import BaseService from "./BaseService";

export default class Hospitals extends BaseService {

  static async getHospitals(companyId) {
    return await this.perform({
      url: '/hospitals',
      method:'get',
      params: {company_id: companyId}
    })
  }

  static async getHospitalsById(id) {
    return await this.perform({
      url: '/hospitals',
      method:'get',
      params: {id: id}
    })
  }

  static async createHospitals(body) {
    return await this.perform({
      url: '/hospitals/',
      method:'post',
      body: body
    })
  }

  static async deleteHospitals(id) {
    return await this.perform({
      url: '/hospitals/',
      method:'delete',
      params: {id: id}
    })
  }

  static async updateHospitals(id, body) {
    return await this.perform({
      url: '/hospitals/',
      method:'patch',
      body: body,
      params: {id: id}
    })
  }
}
