<template>
  <div>
    <div class="mt-15 container card_container">
      <div class="mb-5 titolo">
        <h3 class="text-white mt-5 text-center"><i class="fas fa-clock"></i> Storico Pagamenti</h3>
      </div>
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mb-7 mt-5">
            <div class="h-35px">
              <strong class="text-primary">Assistito</strong>
            </div>
            <b-form-select
              class="form-select"
              v-model="jsonData.assistito"
              :options="assistitoOptions"
              :value="null"
              value-field="value"
              text-field="text"
              @change="getSelectedAssistito"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div v-if="jsonData.storico != null && jsonData.storico.length > 0">
      <div v-for="item in jsonData.storico" :key="item.idTransazione">
        <div class="mt-5 container card_container mb-5">
          <b-card bg-variant="light" class="mb-5">
            <b-container class="bv-example-row pt-lg-6">
              <b-row>
                <b-col sm class="mb-7">
                  <span class="text-primary">Struttura</span><br />
                  <span>ASREM</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Emissione Ricetta</span><br />
                  <span>{{ item.dataEmissioneRicetta }}</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span v-if="item.nre.match(/^\d{4}A\d{10}$/g) != null" class="text-primary">Codice impegnativa</span>
                  <span v-else class="text-primary">Tipo Erogazione</span>
                  <br />
                  <span>{{ item.nre }}</span>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
          <div>
            <b-button class="mx-2 mb-2 btn btn-primary" @click="onScarica(item)" v-b-modal.pdfmodel><i class="fas fa-file-download fs-2x"></i> Ricevuta</b-button>
          </div>
          <div class="my-5">
            <datatable-storico-pagamenti :appuntamenti="item.appuntamenti" :idModal="item.idPrenotazione"></datatable-storico-pagamenti>
          </div>
          <b-card bg-variant="light" class="border-footer-card">
            <b-container class="bv-example-row">
              <b-row class="pt-lg-6">
                <b-col sm class="mb-7">
                  <span class="text-primary">Priorità:</span><span style="margin-left: 10px">{{ item.classePriorita }}</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Tipo esenzione:</span>
                  <span style="margin-left: 10px">N/D</span>
                </b-col>
                <b-col sm class="mb-7">
                  <span class="text-primary">Importo:</span>
                  <span style="margin-left: 10px">{{ item.importo }}</span>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else-if="jsonData.storico != null && jsonData.storico.length === 0">
      <div class="mt-5 container card_container mb-5">
        <b-card bg-variant="light" class="mb-5">
          <b-container class="bv-example-row pt-lg-6">
            <b-row class="text-center">
              <b-col sm class="mb-7">
                <strong><span style="margin-left: 10px" class="text-center">Non ci sono appuntamenti</span></strong>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    <pdf-viewer :pdf="jsonData.pdf" :title="title"></pdf-viewer>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import pdfViewer from "../pdfViewer/pdfViewer.vue";
import axios from "axios";
import datatableStoricoPagamenti from "../datatable/datatableStoricoPagamenti.vue";
import UtilityMixin from "../../utilities/utilityMixin.js";
export default {
  mixins: [UtilityMixin],
  components: { pdfViewer, Loading, datatableStoricoPagamenti },
  data() {
    return {
      pathResourceContatti: "/api/v2.0/appecupt/auth/me",
      pathResorceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourceStoricoPagamenti: "/api/v2.0/appecupt/aslengi/listaPagati",
      pathResourceScarica: "/api/v2.0/appecupt/aslengi/stampaRicevutaPagamento",
      assistitoOptions: [{ value: null, text: "Seleziona Assistito", disabled: true }],
      tokenLogin: null,
      jsonData: {
        storico: [],
        pdf: "",
        assistito: {},
      },
      isLoading: false,
      title: "Fattura",
      token: null,
    };
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.tokenLogin = localStorage.getItem("loginData");
    me.loadData();
    me.loadInfoUtente();
    me.loadStorico();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceContatti;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            struttura: "150021",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.isLoading = false;
          response.data.data.forEach((element) => {
            me.assistitoOptions.push({
              value: element,
              text: `${element.nome} ${element.cognome} - ${element.codiceFiscale}`,
            });
          });
        })
        .catch((error) => {
          me.isLoading = false;
          this.$bvModal.msgBoxOk(error.data, {
            title: "Errore Login",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "vv-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
          UtilityMixin.methods.errorAuthWso2(this, err.response);
          me.isLoading = false;
        });
    },
    loadInfoUtente() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResorceInfoUtente;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.isLoading = false;
          me.assistitoOptions.push({
            value: response.data.data,
            text: `${response.data.data.nome} ${response.data.data.cognome} - ${response.data.data.codiceFiscale}`,
          });
          me.jsonData.assistito = response.data.data;
          me.$emit("getInfoUtente", me.jsonData.assistito);
        })
        .catch((error) => {
          me.isLoading = false;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
    },
    loadStorico() {
      let me = this;
      let link = "https://mocki.io/v1/c8b8eae7-0fe9-438e-807b-445f9339d45b"; //process.env.VUE_APP_URL_BACKEND + me.pathResourceStoricoPagamenti;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            struttura: process.env.VUE_APP_STRUTTURA,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          if (response.data.stato === 200) {
            me.jsonData.storico = response.data.data;
            me.isLoading = false;
          } else {
            me.$bvModal
              .msgBoxOk("Errore scaricamento ricevuta", {
                title: "Errore Caricamento",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
          me.isLoading = false;
        });
    },
    onScarica(item) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceScarica;
      me.isLoading = true;
      axios
        .post(
          link,
          {
            idTransazione: item.idTransazione,
          },
          {
            headers: {
              struttura: process.env.VUE_APP_STRUTTURA,
              "x-access-token": me.tokenLogin,
            },
          }
        )
        .then((response) => {
          me.jsonData.pdf = response.data.data;
          me.isLoading = false;
        })
        .catch((error) => {
          me.isLoading = false;
          error;
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedAssistito(assistito) {
      let me = this;
      me.jsonData.assistito = assistito;
    },
  },
};
</script>
