<template>
  <div>
    <lista-branca @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_BRANCA"></lista-branca>
    <modifica-branca @onIndietro="onIndietro" @saveEdit="saveEdit" v-if="step === PAGINA_MODIFICA_BRANCA" :contatto="jsonData.contattoDaModificare"></modifica-branca>
    <aggiungi-branca @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_BRANCA"></aggiungi-branca>
  </div>
</template>

<script>
import ListaBranca from "./listaBranca.vue";
import ModificaBranca from "./modificaBranca.vue";
import AggiungiBranca from "./aggiungiBranca.vue";

export default {
  components: {
    AggiungiBranca,
    ListaBranca,
    ModificaBranca,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_BRANCA: 0,
      PAGINA_MODIFICA_BRANCA: 1,
      // PAGINA_AS_VIEW: 2,
      PAGINA_AGGIUNGI_BRANCA: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_BRANCA);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_BRANCA);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_AS_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_BRANCA);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_BRANCA);
    },
  },
};
</script>
