<template>
  <div>
    <b-container class="bv-example-row">
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Codice fiscale</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cf"
            value=""
            placeholder="Inserisci codice fiscale"
            required
            :state="validationCodiceFiscale"
            v-model="jsonData.codiceFiscale"
            style="text-transform: uppercase"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Tessera Sanitaria</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cf"
            placeholder="Ultime 5 cifre codice tessera sanitaria"
            value=""
            v-model="jsonData.codiceTS"
            required
            :state="validationCodiceTs"
            style="text-transform: uppercase"
            maxlength="5"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
      </b-row>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </b-container>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UtilityMixin from "../../utilities/utilityMixin";
import axios from "axios";
export default {
  components: { Loading },
  props: {},
  data() {
    return {
      pathResourcePrimoStepRegistrazione: "/api/v2.0/appecupt/codicefiscale/codicefiscaleinverso?scenario=1",
      pathResourceProvincia: "/api/v2.0/appecupt/comuni/getByCodCatastale",
      jsonData: {
        codiceFiscale: "",
        codiceTS: "",
        provincia: "",
      },
      token: null,
      isLoading: false,
    };
  },
  computed: {
    validationCodiceFiscale() {
      var regexCodiceFiscale =
        /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
      return this.jsonData.codiceFiscale.length === 0 ? null : regexCodiceFiscale.test(this.jsonData.codiceFiscale) ? true : false;
    },
    validationCodiceTs() {
      var regexCodiceTs = /^([0-9])*$/;
      return this.jsonData.codiceTS.length === 0 ? null : regexCodiceTs.test(this.jsonData.codiceTS) && this.jsonData.codiceTS.length === 5 ? true : false;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
  },
  watch: {
    "jsonData.codiceFiscale": function (value) {
      this.jsonData.codiceFiscale = value.toUpperCase();
    },
  },
  methods: {
    nextStep() {
      let me = this;
      UtilityMixin.methods.loginWso2();
      me.token = localStorage.getItem("tokenWSO2");
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourcePrimoStepRegistrazione;
      if (!me.validationCodiceFiscale || !me.validationCodiceTs) return;
      me.isLoading = true;
      axios
        .post(link, me.jsonData, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          if (response.data.stato === 200 || response.data.stato === 201) {
            me.$emit("getInfoRegistrazione", response.data.data);
            me.loadProvinciaNascita();
            me.isLoading = false;
          } else {
            this.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
            me.isLoading = false;
          }
        })
        .catch((error) => {
          me.isLoading = false;
          console.log(error);
        });
    },
    loadProvinciaNascita() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceProvincia + "?codcatastale=" + me.jsonData.codiceFiscale.substr(11, 4);
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          me.$emit("getInfoProvincia", response.data.data.provincia.toUpperCase());
        });
    },
  },
};
</script>

<style></style>
