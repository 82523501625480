<template>
  <div>
    <lista-dottori @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_DOTTORI"></lista-dottori>
    <modifica-dottore @onIndietro="onIndietro" @saveEdit="saveEdit" v-if="step === PAGINA_MODIFICA_DOTTORE" :contatto="jsonData.contattoDaModificare"></modifica-dottore>
    <dottore-view v-if="step === PAGINA_DOTTORE_VIEW" :contatto="jsonData.contatto" @saveEdit="saveEdit"></dottore-view>
    <aggiungi-dottore @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_DOTTORE"></aggiungi-dottore>
  </div>
</template>

<script>
import ListaDottori from "./listaDottori.vue";
import ModificaDottore from "./modificaDottore.vue";
import DottoreView from "./dottoreView.vue";
import AggiungiDottore from "./aggiungiDottore.vue";

export default {
  components: {
    ListaDottori,
    ModificaDottore,
    DottoreView,
    AggiungiDottore,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_DOTTORI: 0,
      PAGINA_MODIFICA_DOTTORE: 1,
      PAGINA_DOTTORE_VIEW: 2,
      PAGINA_AGGIUNGI_DOTTORE: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_DOTTORE);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_DOTTORI);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_DOTTORE_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_DOTTORE);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_DOTTORI);
    },
  },
};
</script>
