import BaseService from "./BaseService";

export default class Departments extends BaseService {

  static async getDepartments(hospitalId){
    return await this.perform({
      url: '/departments',
      method: 'get',
      params: {hospital_id: hospitalId}
    })
  }

  static async getDepartmentsById(id){
    return await this.perform({
      url: '/departments',
      method: 'get',
      params: {id: id}
    })
  }

  static async createDepartments(body){
    return await this.perform({
      url: '/departments/',
      method: 'post',
      body: body
    })
  }

  static async deleteDepartments(id){
    return await this.perform({
      url: '/departments/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateDepartments(id, body){
    return await this.perform({
      url: '/departments/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
