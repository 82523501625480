<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Modifica unità operativa</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <div>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Tipo</strong>
                </div>
                <b-form-select class="form-select" :options="tipoRepartoOptions" :value="null" value-field="value" text-field="text" v-model="jsonData.w_type" >
                  <template #first>
                    <b-form-select-option :value="null" disabled>Seleziona tipo</b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Codice</strong>
                </div>
                <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice" value="" v-model="jsonData.code" maxlength="16" />
              </b-col>
              <b-col sm class="mb-7 mt-5" lg="4">
                <div class="h-35px"><strong class="text-primary">Nome</strong></div>
                <b-form-input type="text" :state="validateNome" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.name" />
                <b-form-invalid-feedback :state="validateNome">
                  Campo obbligatorio.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5" lg="4">
                <div class="h-35px"><strong class="text-primary">Contatti</strong></div>
                <div v-for="(telefono, index) in jsonData.contacts" :key="index">
                  <div  class="contattoGrid">
                    <b-form-input class="form-control form-control-solid" name="cf" placeholder="Contatto" v-model="jsonData.contacts[index]"/>
                    <b-button v-if="jsonData.contacts.length > 1" size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteContatto(index)" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                      <b-icon icon="trash" variant="outline-secondary" />
                    </b-button>
                  </div>
                </div>
                <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addContatto" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                  <b-icon icon="plus" variant="outline-secondary" />
                </b-button>
              </b-col>
            </b-row>
            <span class="text-primary">Orari visite ospiti mattina</span>
            <b-form-invalid-feedback :state="validateOrariMattina">
              Tutti i campi devono essere compilati.
            </b-form-invalid-feedback>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Lunedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[0][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[0][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Martedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[1][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[1][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Mercoledì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[2][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[2][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Giovedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[3][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[3][1]"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Venerdì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[4][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[4][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Sabato</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[5][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[5][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Domenica</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_morning[6][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_morning[6][1]"/>
              </b-col>
            </b-row>
            <span class="text-primary">Orari visite ospiti pomeriggio</span>
            <b-form-invalid-feedback :state="validateOrariPomeriggio">
              Tutti i campi devono essere compilati.
            </b-form-invalid-feedback>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Lunedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[0][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[0][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Martedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[1][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[1][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Mercoledì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[2][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[2][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Giovedì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[3][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[3][1]"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Venerdì</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[4][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[4][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Sabato</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[5][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[5][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Domenica</strong></div>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.visiting_times_afternoon[6][0]" style="margin-bottom: 5px"/>
                <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.visiting_times_afternoon[6][1]"/>
              </b-col>
            </b-row>
          </div>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSave()"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Wards from "@/services/Wards";
export default {
  components: { Loading },
  props:{
    contatto: Object
  },
  data() {
    return {
      as: null,
      hospital: null,
      jsonData:this.contatto,
      isLoading: false,
      disableComuneResidenza: true,
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      tipoRepartoOptions: [
        { value: "Unità Operativa Semplice", text: "Unità Operativa Semplice" },
        { value: "Unità Operativa Complessa", text: "Unità Operativa Complessa" },
      ],
    };
  },
  computed: {
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    },
    validateOrariMattina(){
      for (let i=0; i<this.jsonData.visiting_times_morning.length; i++){
        if(this.jsonData.visiting_times_morning[i][0] === null || this.jsonData.visiting_times_morning[i][0] === "")
          return false
        if(this.jsonData.visiting_times_morning[i][1] === null || this.jsonData.visiting_times_morning[i][1] === "")
          return false
      }
      return true
    },
    validateOrariPomeriggio(){
      for (let i=0; i<this.jsonData.visiting_times_afternoon.length; i++){
        if(this.jsonData.visiting_times_afternoon[i][0] === null || this.jsonData.visiting_times_afternoon[i][0] === "")
          return false
        if(this.jsonData.visiting_times_afternoon[i][1] === null || this.jsonData.visiting_times_afternoon[i][1] === "")
          return false
      }
      return true
    }
  },
  mounted: async function () {
    console.log(this.jsonData)
  },

  methods: {
    deleteContatto(index){
      if(this.jsonData.contacts.length > 1)
        this.jsonData.contacts.splice(index, 1)
    },
    addContatto(){
      this.jsonData.contacts.push("");
    },
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    async onSave(){
      if(this.validateNome){
        const id = this.jsonData.id
        delete this.jsonData.id
        const result = await Wards.updateWards(id, this.jsonData)
        if(result.status === 204){
          this.$emit("onIndietro");
        } else {
          console.log(result)
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }
    }
  },
};
</script>
