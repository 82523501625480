<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br/><span class="titoloM2">Gestione unità operative</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onAggiungiReparto"><i class="fa fa-h-square" aria-hidden="true"></i> <strong>Nuova unità operativa</strong></b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista unità operative</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" :value="null" v-model="jsonData.as" value-field="id"
                             :options="asOptions" text-field="name" @change="loadHospitals">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona azienda</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" v-model="jsonData.hospital_id" :value="null" value-field="id"
                             :options="ospedaleOptions" text-field="name" @change="loadDepartments">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona presidio</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" v-model="jsonData.department_id" :value="null" value-field="id"
                             :options="dipartimentoOptions" text-field="name" @change="loadReparti">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona dipartimento</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header">
              <b-table
                  sticky-header
                  ref="table"
                  id="tbl"
                  responsive
                  stacked="lg"
                  striped
                  hover
                  :items="reparti"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  sort-icon-left
                  head-variant="light"
                  class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary"/>
                  </b-button>
                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom"
                            title="Elimina"></b-icon>
                  </b-button>
                  <b-button size="sm" class="no-text mx-1" variant="outline-success" @click="onAdd(row.item)">
                    <b-icon icon="person-plus" variant="outline-success" data-toggle="tooltip" data-placement="bottom"
                            title="Aggiungi medici"></b-icon>
                  </b-button>
                  <b-button size="sm" class="no-text mx-1" variant="outline-info" @click="loadMediciSpecializzazioni(row.item)">
                    <b-icon icon="info" variant="outline-info" data-toggle="tooltip" data-placement="bottom"
                            title="Lista medici"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <b-modal id="modalAggiungi" title="Aggiungi operatore sanitario e branca" hide-header-close hide-footer size="lg" @hide="resetData">
            <b-row>
              <b-col class="mb-7 mt-5">
                <div v-for="(a,index) in medicoSpecializzazione" :key="index" class="listaMedici">
                  <b-row>
                    <b-col style="margin-top: 10px">
                      <div class="h-35px">
                        <strong class="text-primary" style="margin-left: 5px">Operatore sanitario</strong>
                      </div>
                      <b-form-select class="form-select" :value="null" v-model="medicoSpecializzazione[index].doctor_id"
                                     value-field="id" :options="dottoriOptions" text-field="name"  style="margin-left: 5px">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Seleziona op. sanitario</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-col>
                    <b-col style="margin-top: 10px">
                      <div class="h-35px">
                        <strong class="text-primary">Branca</strong>
                      </div>
                      <div v-for="(b,index1) in a.branch_ids" :key="index1">
                        <b-form-select class="form-select" :value="null"
                                       v-model="medicoSpecializzazione[index].branch_ids[index1]" value-field="id"
                                       :options="brancheOptions" text-field="name">
                          <template #first>
                            <b-form-select-option :value="null" disabled>Seleziona branca</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addBranca(index)"
                                  data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: right; margin-top: 4px; margin-bottom: 8px">
                          <b-icon icon="plus" variant="outline-secondary"/>
                        </b-button>
                        <b-button size="sm" class="no-text mx-1" variant="outline-danger" id="removeButton"
                                  @click="removeBranca(index,index1)" data-toggle="tooltip" data-placement="bottom"
                                  title="Rimuovi" style="float: right; margin-top: 4px; margin-bottom: 8px; background-color: white">
                          <b-icon icon="dash" variant="outline-danger"/>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col style="margin-top: 10px">
                      <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addMedico"
                                data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: right">
                        <b-icon icon="plus" variant="outline-secondary"/>
                      </b-button>
                      <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="removeMedico(index)" id="removeButton"
                                data-toggle="tooltip" data-placement="bottom" title="Rimuovi" style="float: right; background-color: white">
                        <b-icon icon="dash" variant="outline-danger"/>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-button variant="success" style="float: right" @click="addMedicoSpecializzazione">Aggiungi</b-button>
            <b-button variant="danger" style="float: right; margin-right: 10px" @click="$bvModal.hide('modalAggiungi')">Cancella</b-button>
          </b-modal>
          <b-modal id="modalRimuovi" title="Operatori sanitari e branche" hide-header-close hide-footer size="lg">
            <div class="b-table-sticky-header">
              <b-table
                  sticky-header
                  ref="table"
                  id="tbl"
                  responsive
                  stacked="lg"
                  striped
                  hover
                  :items="medicoSpecializzazioneSelezionato"
                  :fields="fieldsMedici"
                  :current-page="currentPage"
                  :per-page="perPage"
                  sort-by="doctor.name"
                  sort-icon-left
                  head-variant="light"
                  class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDeleteMedico(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom"
                            title="Elimina"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-modal>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} unità operative</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                              size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots"
               :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
import Hospitals from "@/services/Hospitals";
import Departments from "@/services/Departments";
import Wards from "@/services/Wards";
import UtilityMixin from "@/utilities/utilityMixin";
import Doctors from "@/services/Doctors";
import Branches from "@/services/Branches";
import WardsDoctorsBranches from "@/services/WardsDoctorsBranches";

export default {
  components: {Loading},
  data() {
    return {
      pathResourceReparti: "/wards/",
      jsonData: {department_id: null, hospital_id: null, as: null},
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 14rem",
        },
      ],
      fieldsMedici: [
        {
          label: "Nome",
          key: "doctor.name",
          sortable: true
        },
        {
          label: "Branca",
          key: "branch.name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 1rem",
        },
      ],
      reparti: [],
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      dottoriOptions: [],
      brancheOptions: [],
      isLoading: false,
      repartoSelezionato: null,
      medicoSpecializzazione: [{
        ward_id: null,
        doctor_id: null,
        branch_ids: [null]
      }],
      medicoSpecializzazioneSelezionato: []
    };
  },
  computed: {
    rows() {
      return this.reparti.length;
    },
  },
  mounted: async function () {
    await this.loadAs()
    await this.loadDoctors()
    await this.loadBranche()
  },
  methods: {
    async loadAs() {
      const response = await Companies.getCompanies()
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    async loadHospitals() {
      this.ospedaleOptions = []
      this.dipartimentoOptions = [];
      this.reparti = []
      this.jsonData.hospital_id = null
      this.jsonData.department_id = null
      const response = await Hospitals.getHospitals(this.jsonData.as)
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          this.ospedaleOptions.push(response.data[i])
        }
        this.jsonData.hospital_id = this.ospedaleOptions[0].id
        await this.loadDepartments()
      } else {
        this.$bvToast.toast('Non sono presenti Presidi per questa As.', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadDepartments() {
      this.dipartimentoOptions = [];
      this.reparti = []
      this.jsonData.department_id = null
      const response = await Departments.getDepartments(this.jsonData.hospital_id)
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          this.dipartimentoOptions.push(response.data[i])
        }
        this.jsonData.department_id = this.dipartimentoOptions[0].id
        await this.loadReparti()
      } else {
        this.$bvToast.toast("Non sono presenti Dipartimenti per quest'ospedale.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadReparti() {
      this.reparti = []
      const response = await Wards.getWards(this.jsonData.department_id)
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          this.reparti.push(response.data[i])
        }
      } else {
        this.$bvToast.toast("Non sono presenti Unità Operative per questo dipartimento.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadDoctors() {
      const response = await Doctors.getDoctors()
      if (response.status === 200) {
        this.dottoriOptions = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Operatori Sanitari.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadBranche() {
      const response = await Branches.getBranches()
      if (response.status === 200) {
        this.brancheOptions = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Branche.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadMediciSpecializzazioni(item){
      this.repartoSelezionato = item
      this.medicoSpecializzazioneSelezionato = []
      const response = await WardsDoctorsBranches.getWardsDoctorsBranches(item.id)
      if(response.status === 200){
        this.medicoSpecializzazioneSelezionato = response.data
        this.$bvModal.show('modalRimuovi')
      } else {
        this.$bvToast.toast("Non sono presenti Operatori Sanitari e Branche associate a questa Unità Operativa.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    addMedico() {
      this.medicoSpecializzazione.push({
        ward_id: this.repartoSelezionato.id,
        doctor_id: null,
        branch_ids: [null]
      });
    },
    removeMedico(item) {
      if (this.medicoSpecializzazione.length > 1)
        this.medicoSpecializzazione.splice(item, 1)
    },
    addBranca(index) {
      this.medicoSpecializzazione[index].branch_ids.push(null)
    },
    removeBranca(index, index1) {
      if (this.medicoSpecializzazione[index].branch_ids.length > 1)
        this.medicoSpecializzazione[index].branch_ids.splice(index1, 1)
    },
    onAdd(item) {
      this.repartoSelezionato = item
      this.medicoSpecializzazione[0].ward_id = this.repartoSelezionato.id
      this.$bvModal.show('modalAggiungi')
    },
    resetData(){
      this.medicoSpecializzazione = [{
        ward_id: null,
        doctor_id: null,
        branch_ids: [null]
      }]
    },
    async addMedicoSpecializzazione(){
      for(let i=0; i<this.medicoSpecializzazione.length; i++){
        const resposne = await WardsDoctorsBranches.addWardsDoctorsBranches([this.medicoSpecializzazione[i]])
        if(resposne.status === 204){
          this.$bvToast.toast("L'operazione è andata a buon fine.", {
            title: `Congratulazioni`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            noCloseButton: true
          })
          this.$bvModal.hide('modalAggiungi')
        } else {
          console.log(resposne);
          this.$bvToast.toast("Si è verificato un errore", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'warning',
            noCloseButton: true
          })
        }
      }
    },
    async onDeleteMedico(item){
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare l'operatore sanitario?", {
            title: "Elimina operatore sanitario",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const body = {
                ward_id: this.repartoSelezionato.id,
                doctor_id: item.doctor.id,
                branch_id: item.branch.id
              }
              const response = await WardsDoctorsBranches.deleteWardsDoctorsBranches([body])
              if(response.status === 204){
                this.$bvModal
                    .msgBoxOk("Premere ok per procedere", {
                      title: "Operatore sanitario eliminato",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadMediciSpecializzazioni(this.repartoSelezionato)
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
              } else {
                this.$bvToast.toast('Riprovare più tardi', {
                  title: `Errore`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'danger',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });




      console.log(item,this.repartoSelezionato)
    },
    onEdit(item) {
      let me = this;
      me.$emit("onEdit", item);
    },
    async onDelete(item) {
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare l'unità operativa?", {
            title: "Elimina unità operativa",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const res = await Wards.deleteWards(item.id)
              if (res.status === 204) {
                this.$bvModal
                    .msgBoxOk("Premere ok per procedere", {
                      title: "Unità operativa eliminata",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadReparti();
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
              } else {
                this.$bvToast.toast('Riprovare più tardi', {
                  title: `Errore`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'danger',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onAggiungiReparto() {
      let me = this;
      me.$emit("onAggiungi");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
  },
};
</script>
<style>

.listaMedici:nth-child(even){
  background-color: #ececec
}

#removeButton:hover {
  background-color: #dc3545 !important;
}

</style>
