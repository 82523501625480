import BaseService from "./BaseService";

export default class Doctors extends BaseService {

  static async getDoctors(branchId){
    return await this.perform({
      url: '/doctors',
      method: 'get',
      params: {branch_id: branchId}
    })
  }

  static async getDoctorsPagination(page, size){
    return await this.perform({
      url: '/doctors/limit',
      method: 'get',
      params: {page: page, size: size}
    })
  }

  static async getDoctorsById(id){
    return await this.perform({
      url: '/doctors',
      method: 'get',
      params: {id: id}
    })
  }

  static async createDoctors(body){
    return await this.perform({
      url: '/doctors/',
      method: 'post',
      body: body
    })
  }

  static async deleteDoctors(id){
    return await this.perform({
      url: '/doctors/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateDoctors(id, body){
    return await this.perform({
      url: '/doctors/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
