<template>
  <div>
    <div class="mt-10 container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione Utente</span></p>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col sm="2">
          <b-button class="my-2 btn-primary" @click="onAggiungiContatto"><i class="fa fa-user-plus" aria-hidden="true"></i> Nuovo utente </b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <template>
            <b-form @submit.prevent="onSubmit"> </b-form>
          </template>
          <template>
            <div class="b-table-sticky-header">
              <b-table
                sticky-header
                ref="table"
                id="tbl"
                :filter="filtro"
                responsive
                stacked="lg"
                striped
                hover
                :items="contatti"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} utenti</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utilities/utilityMixin";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceListaContatti: "/api/v2.0/appecupt/auth/me",
      pathResourceDelete: "/api/v2.0/appecupt/auth/eliminaContatto",
      currentPage: 1,
      perPage: 10,
      filtro: { cognome: "" },
      fields: [
        {
          label: "Nome",
          key: "nome",
        },
        {
          label: "Cognome",
          key: "cognome",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      contatti: [],
      token: null,
      tokenLogin: null,
      isLoading: false,
    };
  },
  computed: {
    rows() {
      return this.contatti.length;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.tokenLogin = localStorage.getItem("loginData");
    //me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaContatti;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            struttura: "150021",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          if (response.data.stato === 200 || response.data.stato === 201) {
            me.contatti = response.data.data;
            me.isLoading = false;
          } else {
            me.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore Annullamento",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.isLoading = false;
              })
              .catch(() => {});
          }
          me.isLoading = false;
        })
        .catch((error) => {
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
    },
    onEdit(item) {
      let me = this;
      me.$emit("onEdit", item);
    },
    onDelete(item) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceDelete;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di voler eliminare il contatto?", {
          title: "Elimina Contatto",
          footerClass: "p-2",
          centered: true,
        })
        .then((response) => {
          if (response) {
            axios
              .post(
                link,
                { codiceFiscale: item.codiceFiscale },
                {
                  headers: {
                    struttura: "150901",
                    Authorization: `Bearer ${me.token}`,
                    "x-access-token": me.tokenLogin,
                    versioneapp: "5",
                  },
                }
              )
              .then((response) => {
                if (response.data.stato === 200) {
                  this.$bvModal
                    .msgBoxOk(response.data.data.messaggioSuccessoRegistrazione, {
                      title: "Contatto Eliminato",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      me.isLoading = false;
                      me.loadData();
                    })
                    .catch((err) => {
                      me.isLoading = false;
                      console.log(err);
                    });
                } else {
                  this.$bvModal
                    .msgBoxOk(response.data.errore, {
                      title: "Errore",
                      size: "md",
                      okVariant: "outline-danger",
                      headerClass: "vv-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {})
                    .catch((err) => {
                      me.isLoading = false;
                      console.log(err);
                    });

                  me.isLoading = false;
                  me.loadData();
                }
              })
              .catch((err) => {
                err;
                me.loadData();
              });
          }
          me.loadData();
        })
        .catch((error) => {
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          me.loadData();
        });
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onSubmit() {
      let me = this;
      me.contatti = me.contatti.filter((element) => element.cognome.toUpperCase().includes(me.filtro.cognome.toUpperCase()));
    },
    onReset() {
      let me = this;
      me.contatti = [];
      me.loadData();
    },
    onAggiungiContatto() {
      let me = this;
      me.$emit("onAggiungi");
    },
  },
};
</script>
