<template>
  <div>
    <div class="mt-10 container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione aziende</span></p>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onAggiungiAs"><i class="fa fa-h-square" aria-hidden="true"></i> Nuova azienda</b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista aziende</strong>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header mt-5">
              <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="as"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                sort-desc.sync= true
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} aziende</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import UtilityMixin from "../../utilities/utilityMixin";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceAs: "/companies/",
      currentPage: 1,
      perPage: 5,
      sortBy: "name",
      fields: [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      as: [],
      token: null,
      tokenLogin: null,
      isLoading: false,
      id: "",
    };
  },
  computed: {
    rows() {
      return this.as.length;
    },
  },
  mounted: function () {
    this.id = 0;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.as = [];
      const aziende = await Companies.getCompanies()
      if(aziende.status === 200){
        for (let i=0; i<aziende.data.length; i++){
          this.as.push(aziende.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    onEdit(item) {
      this.$emit("onEdit", item);
    },
    async onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di voler eliminare l'Azienda?", {
          title: "Elimina Azienda",
          footerClass: "p-2",
          centered: true,
        })
        .then(async (response) => {
          if (response) {
            const res = await Companies.deleteCompanies(item.id)
            if(res.status !== 204){
              this.$bvToast.toast('Riprovare più tardi', {
                title: `Errore`,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                variant: 'danger',
                noCloseButton: true
              })
            }
          }
          this.loadData();
        })
        .catch((error) => {
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          this.loadData();
        });
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onSubmit() {
      let me = this;
      me.as = me.contatti.filter((element) => element.cognome.toUpperCase().includes(me.filtro.cognome.toUpperCase()));
    },
    onReset() {
      let me = this;
      me.contatti = [];
      me.loadData();
    },
    onAggiungiAs() {
      let me = this;
      me.$emit("onAggiungi");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
  },
};
</script>
