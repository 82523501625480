<template>
  <div>
    <lista-ospedali @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_OSPEDALI"></lista-ospedali>
    <modifica-ospedale @saveEdit="saveEdit" @onIndietro="onIndietro" v-if="step === PAGINA_MODIFICA_OSPEDALE" :contatto="jsonData.contattoDaModificare"></modifica-ospedale>
    <!-- <dottore-view v-if="step === PAGINA_OSPEDALE_VIEW" :contatto="jsonData.contatto" @saveEdit="saveEdit"></dottore-view> -->
    <aggiungi-ospedale @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_OSPEDALE"></aggiungi-ospedale>
  </div>
</template>

<script>
import ListaOspedali from "./listaOspedali.vue";
import ModificaOspedale from "./modificaOspedale.vue";
// import DottoreView from "./ospeda.vue";
import AggiungiOspedale from "./aggiungiOspedale.vue";

export default {
  components: {
    ListaOspedali,
    ModificaOspedale,
    // DottoreView,
    AggiungiOspedale,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_OSPEDALI: 0,
      PAGINA_MODIFICA_OSPEDALE: 1,
      PAGINA_OSPEDALE_VIEW: 2,
      PAGINA_AGGIUNGI_OSPEDALE: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_OSPEDALE);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_MODIFICA_OSPEDALE);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_OSPEDALE_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_OSPEDALE);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_OSPEDALI);
    },
  },
};
</script>
