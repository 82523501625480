<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Nuova azienda</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Codice</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice" value="" v-model="jsonData.code" />
            </b-col>
            <!--
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Codice Regionale</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice Regionale" value="" v-model="jsonData.regional_code" />
            </b-col>
            -->
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Tipo</strong>
              </div>
              <b-form-select class="form-select" name="cf" :options="tipoAsOptions" :value="null" value-field="value" text-field="text" v-model="jsonData.c_type" />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong class="text-primary">Nome</strong></div>
              <b-form-input type="text" :state="validateNome" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.name" />
              <b-form-invalid-feedback :state="validateNome">
                Il campo non può essere vuoto.
              </b-form-invalid-feedback>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Indirizzo</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cognome" placeholder="Indirizzo" value="" v-model.trim="jsonData.address" />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Contatti</strong>
              </div>
              <div v-for="(telefono, index) in jsonData.contacts" :key="index">
                <div  class="contattoGrid">
                  <b-form-input class="form-control form-control-solid" name="cf" placeholder="Contatto" v-model="jsonData.contacts[index]"/>
                  <b-button v-if="jsonData.contacts.length > 1" size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteContatto(index)" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                    <b-icon icon="trash" variant="outline-secondary" />
                  </b-button>
                </div>
              </div>
              <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addContatto" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                <b-icon icon="plus" variant="outline-secondary" />
              </b-button>
            </b-col>
          </b-row>
          <div>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Sito web</strong></div>
                <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Sito web" value="" v-model.trim="jsonData.website" />
              </b-col>
            </b-row>
          </div>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSalva"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceAs: "/companies/",
      jsonData: { c_type: null, contacts:[""] },
      isLoading: false,
      tipoAsOptions: [
        { value: null, text: "Seleziona tipo", disabled: true },
        { value: "ARNAS", text: "ARNAS" },
        { value: "AO", text: "AO" },
        { value: "ASP", text: "ASP" },
      ],
    };
  },
  computed: {
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    },
  },

  methods: {
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    addContatto(){
      this.jsonData.contacts.push("");
    },
    deleteContatto(index){
      if(this.jsonData.contacts.length > 1)
        this.jsonData.contacts.splice(index, 1)
    },
    async onSalva() {
      if(this.validateNome){
        const newAs ={
          code: this.jsonData.code,
          regional_code: this.jsonData.regional_code,
          c_type: this.jsonData.c_type,
          name: this.jsonData.name,
          address: this.jsonData.address,
          contacts: this.jsonData.contacts,
          website: this.jsonData.website
        }
        const result = await Companies.createCompanies(newAs)
        if(result.status === 204){
          this.$emit("onIndietro");
        } else {
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }

    },
  },
};
</script>
