<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione branche</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onAggiungiReparto"><i class="fa fa-h-square" aria-hidden="true"></i> <strong> Nuova branca</strong></b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista branche</strong>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header mt-5">
              <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="branche"
                :fields="fields"
                :per-page="perPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} branche</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Branches from "@/services/Branches";
import UtilityMixin from "@/utilities/utilityMixin";
export default {
  components: { Loading },
  data() {
    return {
      as: null,
      hospital_id: null,
      department_id: null,
      jsonData: {
        ward_id: null,
      },
      currentPage: 1,
      perPage: 4,
      rows: 0,
      fields: [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      branche: [],
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      repartoOptions: [],
      isLoading: false,
    };
  },
  computed: {},
  mounted: async function () {
    await this.totalRows()
    await this.loadBranche()
  },
  watch:{
    async currentPage(){
      await this.loadBranche()
    }
  },
  methods: {
    async totalRows() {
      const response = await Branches.getBranchesPagination(1, 1)
      this.rows = response.data.total
    },
    async loadBranche(){
    this.branche = []
      const response = await Branches.getBranchesPagination(this.currentPage, this.perPage)
      if(response.status === 200){
        for(let i=0; i<response.data.items.length; i++){
          this.branche.push(response.data.items[i])
        }
      } else {
        this.$bvToast.toast("Non sono presenti Branche per questo Reparto.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    onEdit(item) {
      let me = this;
      me.$emit("onEdit", item);
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onAggiungiReparto() {
      let me = this;
      me.$emit("onAggiungi");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    async onDelete(item){
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare la branca?", {
            title: "Elimina Branca",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const res = await Branches.deleteBranches(item.id)
              if(res.status === 204){
                this.$bvModal
                    .msgBoxOk("Premere ok per procedere",{
                      title: "Branca Eliminata",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadBranche();
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
              } else {
                this.$bvToast.toast('Riprovare più tardi', {
                  title: `Errore`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'danger',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
  },
};
</script>
