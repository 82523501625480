<template>
  <div>
    <lista-reparti @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_REPARTI"></lista-reparti>
    <modifica-reparto @saveEdit="saveEdit" @onIndietro="onIndietro" v-if="step === PAGINA_MODIFICA_REPARTO" :contatto="jsonData.contattoDaModificare"></modifica-reparto>
    <aggiungi-reparto @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_REPARTO"></aggiungi-reparto>
  </div>
</template>

<script>
import ListaReparti from "./listaReparti.vue";
import ModificaReparto from "./modificaReparto.vue";
import AggiungiReparto from "./aggiungiReparto.vue";

export default {
  components: {
    ListaReparti,
    ModificaReparto,
    AggiungiReparto,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_REPARTI: 0,
      PAGINA_MODIFICA_REPARTO: 1,
      // PAGINA_AS_VIEW: 2,
      PAGINA_AGGIUNGI_REPARTO: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_REPARTO);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_REPARTI);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_AS_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_REPARTO);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_REPARTI);
    },
  },
};
</script>
