<template>
  <div>
    <div>
      <b-container class="bv-example-row">
        <div class="mt-15 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong>Nome</strong></div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="nome"
                placeholder="Nome"
                value=""
                v-model="jsonData.assistito.nome"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Cognome</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cognome"
                placeholder="Cognome"
                value=""
                v-model="jsonData.assistito.cognome"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Codice fiscale</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cf"
                placeholder="Codice Fiscale"
                value=""
                v-model="jsonData.assistito.codiceFiscale"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong>Data di nascita</strong></div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="datanascita"
                placeholder="Data di nascita"
                value=""
                v-model="jsonData.assistito.dataNascita"
                locale="it"
                disabled
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Luogo di nascita</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="luogonascita"
                value=""
                v-model="jsonData.assistito.luogoDiNascita"
                style="text-transform: uppercase"
                disabled
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Sesso</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.assistito.sesso" :options="sessoOptions" disabled> </b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Stato civile</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.assistito.statoCivile" :options="statoCivileOptions" :value="null"></b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Provincia di residenza</strong>
              </div>
              <b-form-select
                class="form-select"
                v-model="jsonData.assistito.residenza.provincia"
                :options="provinciaResidenzaOptions"
                :value="null"
                value-field="value"
                text-field="text"
                @change="getSelectedProvinciaResidenza"
                style="text-transform: uppercase"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong> Comune di residenza</strong>
              </div>
              <b-form-select
                class="form-select"
                v-model="jsonData.assistito.residenza.comune"
                :value="null"
                value-field="value"
                :options="comuniResidenzaOptions"
                text-field="text"
                style="text-transform: uppercase"
              ></b-form-select>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Indirizzo di residenza</strong>
              </div>
              <b-form-input
                v-model="jsonData.assistito.indirizzoResidenza"
                :value="null"
                value-field="value"
                text-field=""
                style="text-transform: uppercase"
                type="text"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="nazionalitaStraniera">
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Nazionalità</strong>
              </div>
              <b-form-select class="form-select" v-model="jsonData.cittadinanza" :options="cittadinanzaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Cellulare</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="provincianascita"
                placeholder="Cellulare"
                value=""
                required
                maxlength="10"
                :state="validationCellulare"
                v-model="jsonData.assistito.numeroCellulare"
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>Telefono fisso</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="provincianascita"
                placeholder="Telefono fisso"
                value=""
                required
                maxlength="10"
                :state="validationFisso"
                v-model="jsonData.assistito.numeroFisso"
              />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong>E-mail</strong>
              </div>
              <b-form-input
                type="email"
                class="form-control form-control-solid"
                name="email"
                placeholder=""
                value=""
                v-model.trim="jsonData.assistito.email"
                required
                :state="validationEmail"
                @keydown.space.prevent
              />
            </b-col>
          </b-row>
          <b-row> </b-row>
          <div class="footer d-flex justify-content-end">
            <b-button variant="light" class="text-primary" @click="onIndietro"> <i class="fas fa-angle-double-left"></i> Indietro </b-button>

            <b-button class="ml-2 btn-primary" @click="onModifica"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#20336a" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UtilityMixin from "../../utilities/utilityMixin";
export default {
  components: { Loading },
  mixins: [UtilityMixin],
  props: {
    assistito: Object,
  },
  data() {
    return {
      jsonData: {
        assistito: {
          nome: this.assistito.nome,
          cognome: this.assistito.cognome,
          statoCivile: {
            id: this.assistito.codStatoCivile,
            descrizione: this.assistito.statoCivile,
          },
          residenza: {
            provincia: {
              codIstat: this.assistito.codIstatProvinciaResidenza,
              provincia: this.assistito.provinciaResidenza,
            },
            comune: {
              cap: this.assistito.capResidenza,
              codice: this.assistito.codIstatComuneResidenza,
              nome: this.assistito.comuneResidenza,
              provinciaSigla: this.assistito.provinciaResidenzaSigla,
            },
          },
          codiceFiscale: this.assistito.codiceFiscale,
          dataNascita: this.assistito.dataNascita,
          luogoDiNascita: this.assistito.luogoDiNascita,
          sesso: this.assistito.sesso,
          indirizzoResidenza: this.assistito.indirizzoResidenza,
          numeroCellulare: this.assistito.numeroCellulare,
          numeroFisso: this.assistito.numeroFisso,
          email: this.assistito.email,
        },
      },
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [
        { value: null, text: "Seleziona la Provincia", disabled: true },
        {
          value: {
            provincia: this.assistito.provinciaResidenza,
            codIstat: this.assistito.codIstatProvinciaResidenza,
          },
          text: this.assistito.provinciaResidenza.toUpperCase(),
        },
      ],
      cittadinanzaOptions: [{ value: null, text: "Seleziona la cittadinanza", disabled: true }],
      comuniResidenzaOptions: [
        { value: null, text: "Seleziona il Comune", disabled: true },
        {
          value: {
            cap: this.assistito.capResidenza,
            codice: this.assistito.codIstatComuneResidenza,
            nome: this.assistito.comuneResidenza,
            provinciaSigla: this.assistito.provinciaResidenzaSigla,
          },
          text: this.assistito.comuneResidenza,
        },
      ],
      statoCivileOptions: [{ value: null, text: "Seleziona Stato Civile", disabled: true }],
      pathResourceListaProvince: "/api/v2.0/appecupt/comuni/listaprovince",
      pathResourceStatoCivile: "/api/v2.0/appecupt/statocivile",
      pathResourceListaComuni: "/api/v2.0/appecupt/comuni/listacomuni",
      pathResourceListaNazioni: "/api/v2.0/appecupt/nazioni/",
      pathResourceModificaContatto: "/api/v2.0/appecupt/auth/modAssistito",
      token: null,
      nazionalitaStraniera: false,
      isLoading: false,
    };
  },
  computed: {
    validationCellulare() {
      var regexCellulare = /^3\d{8,9}$/;

      return regexCellulare.test(this.jsonData.assistito.numeroCellulare) ? true : false;
    },
    validationFisso() {
      let regexFisso = /^0\d+$/;
      return !this.jsonData.numeroFisso || this.jsonData.numeroFisso.length === 0 ? null : regexFisso.test(this.jsonData.numeroFisso) ? true : false;
    },
    validationEmail() {
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.jsonData.assistito.email.length === 0 ? null : regexEmail.test(this.jsonData.assistito.email) ? true : false;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.tokenLogin = localStorage.getItem("loginData");
    me.loadListaProvince();
    if (me.assistito.codIstatProvinciaResidenza) me.getSelectedProvinciaResidenza(null);
    me.loadStatoCivile();
    me.controlloNazionalita();
  },
  methods: {
    loadListaProvince() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaProvince;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            if (element.codIstat !== me.assistito.codIstatProvinciaResidenza) {
              me.provinciaResidenzaOptions.push({
                value: element,
                text: element.provincia.toUpperCase(),
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadStatoCivile() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceStatoCivile;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.statoCivileOptions.push({
              value: element,
              text: element.descrizione.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedProvinciaResidenza(provinciaResidenza = null) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaComuni;
      me.comuniResidenzaOptions = [{ value: null, text: "Seleziona il Comune", disabled: true }];
      me.jsonData.assistito.residenza.comune = provinciaResidenza == null ? me.jsonData.assistito.residenza.comune : null;
      axios
        .post(
          link,
          {
            codIstat: provinciaResidenza == null ? me.assistito.codIstatProvinciaResidenza : provinciaResidenza.codIstat,
          },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            me.comuniResidenzaOptions.push({
              value: element,
              text: element.nome.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    controlloNazionalita() {
      let me = this;
      if (me.jsonData.codiceFiscale != null && me.jsonData.codiceNazionalita !== "100") me.nazionalitaStraniera = true;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaNazioni;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.cittadinanzaOptions.push({
              value: element,
              text: element.descrizione,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onModifica() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceModificaContatto;
      if (!me.validationCellulare || !me.validationFisso || !me.validationEmail) return;
      me.isLoading = true;
      const datiRegistrazione = {
        codiceFiscale: me.jsonData.assistito.codiceFiscale,
        codiceTS: me.jsonData.assistito.codiceTS,
        nome: me.jsonData.assistito.nome,
        cognome: me.jsonData.assistito.cognome,
        dataNascita: me.jsonData.assistito.dataNascita,
        sesso: me.jsonData.assistito.sesso,
        scadenzaTS: me.jsonData.assistito.scadenzaTS,
        luogoDiNascita: me.jsonData.assistito.luogoDiNascita,
        istatLuogoDiNascita: me.jsonData.assistito.istatLuogoDiNascita,
        statoCivile: me.jsonData.assistito.statoCivile.descrizione,
        codStatoCivile: me.jsonData.assistito.statoCivile.id,
        provinciaResidenza: me.jsonData.assistito.residenza.provincia.provincia,
        codIstatProvinciaResidenza: me.jsonData.assistito.residenza.provincia.codIstat,
        comuneResidenza: me.jsonData.assistito.residenza.comune.nome,
        codIstatComuneResidenza: me.jsonData.assistito.residenza.comune.codice,
        provinciaResidenzaSigla: me.jsonData.assistito.residenza.comune.provinciaSigla,
        strutturaPreferita: "150901",
        istatCittadinanza: me.jsonData.assistito.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.assistito.cittadinanza.istat : me.jsonData.assistito.codiceNazionalita,
        indirizzoResidenza: me.jsonData.assistito.indirizzoResidenza,
        capResidenza: me.jsonData.assistito.residenza.comune.cap,
        cittadinanza: me.jsonData.assistito.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.assistito.cittadinanza.descrizione : "ITALIA",
        terminiServizio: me.jsonData.assistito.terminiServizio,
        numeroCellulare: me.jsonData.assistito.numeroCellulare,
        numeroFisso: me.jsonData.assistito.numeroFisso,
        codiceNazionalita: me.jsonData.assistito.codiceNazionalita,
        controlloStep3: true,
        controlloStep1: true,
        controlloStep2: true,
        email: me.jsonData.assistito.email,
      };

      axios
        .post(link, datiRegistrazione, {
          headers: {
            struttura: "150021",
            versioneapp: "5",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          if (response.data.stato === 200 || response.data.stato === 201) {
            this.$bvModal
              .msgBoxOk(response.data.data.messaggioSuccessoRegistrazione, {
                title: "Utente Modificato",
                size: "md",
                okVariant: "outline-success",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.$emit("saveEdit");
                me.isLoading = false;
              })
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          } else {
            this.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
            me.isLoading = false;
          }
        })
        .catch((error) => {
          error;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
      me.isLoading = false;
    },
    onIndietro() {
      let me = this;
      me.$emit("saveEdit");
    },
  },
};
</script>
