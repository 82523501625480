import BaseService from "./BaseService";

export default class WardsDoctorsBranches extends BaseService {

    static async getWardsDoctorsBranches(wardId){
        return await this.perform({
            url:'/wards_doctors_branches',
            method: 'get',
            params: {ward_id: wardId}
        })
    }

    static async addWardsDoctorsBranches(body){
        return await this.perform({
            url:'/wards_doctors_branches/',
            method: 'post',
            body: body
        })
    }

    static async deleteWardsDoctorsBranches(body){
        return await this.perform({
            url:'/wards_doctors_branches/',
            method: 'delete',
            body: body
        })
    }
}
