import BaseService from "./BaseService";

export default class Branches extends BaseService {

  static async getBranches(wardId){
    return await this.perform({
      url: '/branches',
      method: 'get',
      params: {ward_id: wardId}
    })
  }

  static async getBranchesPagination(page, size){
    return await this.perform({
      url: '/branches/limit',
      method: 'get',
      params: {page: page, size: size}
    })
  }

  static async getBranchesById(id){
    return await this.perform({
      url: '/branches',
      method: 'get',
      params: {id: id}
    })
  }

  static async createBranches(body){
    return await this.perform({
      url: '/branches/',
      method: 'post',
      body: body
    })
  }

  static async deleteBranches(id){
    return await this.perform({
      url: '/branches/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateBranches(id, body){
    return await this.perform({
      url: '/branches/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
