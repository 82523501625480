<template>
  <div>
    <b-container class="mt-15 container card_container">
      <h3 class="mb-5 titolo"><i class="fas fa-user"></i> Profilo Utente</h3>
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Nome</strong>
          <br />
          <span>{{ assistito.nome }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Cognome</strong>
          <br />
          <span>{{ assistito.cognome }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Codice Fiscale</strong>
          <br />
          <span>{{ assistito.codiceFiscale }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Luogo di Nascita</strong>
          <br />
          <span>{{ assistito.luogoDiNascita }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Data di Nascita</strong>
          <br />
          <span>{{ assistito.dataNascita }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Sesso</strong>
          <br />
          <span>{{ assistito.sesso }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Stato Civile</strong>
          <br />
          <span>{{ assistito.statoCivile }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="4">
          <strong>Provincia di Residenza</strong>
          <br />
          <span> {{ assistito.provinciaResidenza }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Comune di Residenza</strong>
          <br />
          <span>{{ assistito.comuneResidenza }}</span>
        </b-col>
        <b-col cols="6" xs="12" sm="12" md="12" lg="6">
          <strong>Indirizzo di Residenza</strong>
          <br />
          <span>{{ assistito.indirizzoResidenza }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Cellulare</strong>
          <br />
          <span>{{ numeroCellulareAssente }}</span>
        </b-col>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Telefono Fisso</strong>
          <br />
          <span>{{ numeroFissoAssente }}</span>
        </b-col>
        <b-col cols="4" xs="12" sm="12" md="12" lg="4">
          <strong>Email</strong>
          <br />
          <span>{{ assistito.email }}</span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <div class="footer d-flex justify-content-end">
          <b-button variant="outline-primary" class="ml-2 text-primary-w" @click="onModifica"> <i class="fas fa-pen"></i> Modifica </b-button>
          <b-button variant="danger" class="ml-2 text-primary-w" @click="onElimina"> <i class="fas fa-trash"></i> Elimina </b-button>
        </div>
      </b-row>
    </b-container>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utilities/utilityMixin.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { Loading },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResorceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourceEliminaContatto: "/api/v2.0/appecupt/auth/eliminaaccount",
      assistito: {},
      tokenLogin: null,
      isLoading: false,
    };
  },
  computed: {
    numeroCellulareAssente() {
      return this.assistito.numeroCellulare === "" || this.assistito.numeroCellulare === null ? "NUMERO CELLULARE ASSENTE" : this.assistito.numeroCellulare;
    },
    numeroFissoAssente() {
      return this.assistito.numeroFisso === "" || this.assistito.numeroFisso === null ? "NUMERO TELEFONO FISSO ASSENTE" : this.assistito.numeroFisso;
    },
  },
  mounted: function () {
    let me = this;
    me.tokenLogin = localStorage.getItem("loginData");
    me.token = localStorage.getItem("tokenWSO2");
    me.loadInfoUtente();
  },
  methods: {
    loadInfoUtente() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResorceInfoUtente;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.isLoading = false;
          me.assistito = response.data.data;
        })
        .catch((error) => {
          me.isLoading = false;
          error;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
    },
    onElimina() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceEliminaContatto;

      this.$bvModal
        .msgBoxConfirm("Eliminando il profilo Utente eliminerai anche i contatti, Sei sicuro di voler continuare?", {
          title: "Conferma Eliminazione",
          footerClass: "p-2",
          centered: true,
        })
        .then((response) => {
          if (response) {
            me.isLoading = true;
            axios
              .post(
                link,
                { password: me.assistito.password },
                {
                  headers: {
                    "x-access-token": me.tokenLogin,
                  },
                }
              )
              .then((response) => {
                if (response.data.stato === 200) {
                  me.$bvModal
                    .msgBoxOk(response.data.data, {
                      title: "Esito Eliminazione",
                      size: "md",
                      okVariant: "primary",
                      headerClass: "header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      me.$router.replace("/#/prenota").catch((err) => {
                        console.log(err);
                      });
                    })
                    .catch(() => {});
                } else {
                  me.$bvModal
                    .msgBoxOk(response.data.errore, {
                      title: "Errore Eliminazione",
                      size: "md",
                      okVariant: "outline-danger",
                      headerClass: "vv-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {})
                    .catch(() => {});
                }
              })
              .catch((error) => {
                me.isLoading = false;
                UtilityMixin.methods.errorAuthWso2(this, error.response);
                error;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onModifica() {
      let me = this;
      me.$emit("modificaUtente", me.assistito);
    },
  },
};
</script>
