<template>
  <div>
    <b-table striped hover :items="appuntamenti" responsive stacked="lg" :fields="fieldsAppuntamenti" ok-only @hide="resetModalNota">
      <template v-slot:cell(dataEora)="data">
        {{ data.item.dataAppuntamento }}
      </template>
      <template v-slot:cell(actions)="row">
        <a v-if="row.item.booleanNota" href="" onclick="return false" class="vv-link" @click="info(row.item, row.index, $event.target)">
          <i class="fas fa-info-circle"></i>
        </a>
        <span v-else>N/D</span>
      </template>
    </b-table>
    <b-modal :id="idModal" class="modal-content" title="Nota Appuntamento" slot="modal-header" hide-header-close @hide="resetModalNota" size="lg">
      <b-container>
        <b-row>
          <b-col>
            <p class="vv-text">{{ modalNota.content }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    appuntamenti: Array,
    idModal: String,
  },
  data() {
    return {
      token: null,
      tokenLogin: null,
      fieldsAppuntamenti: [
        {
          label: "Prestazione",
          key: "desPrestazione",
        },
        {
          label: "Data E Ora",
          key: "dataEora",
        },
        {
          label: "Reparto",
          key: "ambulatorio.descrizione",
        },
        {
          label: "Indicazioni Reparto",
          key: "ambulatorio.ubicazioneStruttura",
          thStyle: "width: 15%",
        },
      ],
      modalNota: { title: "", content: "" },
    };
  },
  mounted: function () {},
  methods: {
    info(item, link) {
      let me = this;
      if (item.booleanNota) {
        me.modalNota.content = item.nota;
      } else {
        me.modalNota.content = "Non è presente nessuna Nota";
      }
      me.$root.$emit("bv::show::modal", me.idModal, link);
    },
    resetModalNota() {
      let me = this;
      me.modalNota.content = "";
    },
  },
};
</script>
