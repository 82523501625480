<template>
  <b-modal class="vv-pdf" size="xl" id="pdfmodel" :title="title" hide-header-close>
    <b-embed class="vv-pdf h-800px" type="iframe" :src="urlEncodePdf(pdf)" allowfullscreen></b-embed>
  </b-modal>
</template>
<script>
export default {
  props: {
    pdf: String,
    title: String,
  },
  methods: {
    urlEncodePdf(pdf) {
      if (!pdf) return "";
      return `data:application/pdf;base64,${pdf}`;
    },
  },
};
</script>
<style scoped>
.vv-pdf {
  width: 100%;
}
</style>
