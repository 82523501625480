<template>
  <div>
    <div class="mt-15 container card_container">
      <b-container class="bv-example-row">
        <b-row>
          <b-col cols="4" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Nome</strong>
            <br />
            <span>{{ contatto.nome }}</span>
          </b-col>
          <b-col cols="4" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Cognome</strong>
            <br />
            <span>{{ contatto.cognome }}</span>
          </b-col>
          <b-col cols="4" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Codice Fiscale</strong>
            <br />
            <span>{{ contatto.codiceFiscale }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Luogo di Nascita</strong>
            <br />
            <span>{{ contatto.luogoDiNascita }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Data di Nascita</strong>
            <br />
            <span>{{ contatto.dataNascita }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Sesso</strong>
            <br />
            <span>{{ contatto.sesso }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Stato Civile</strong>
            <br />
            <span>{{ contatto.statoCivile }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="12" lg="4" class="my-2">
            <strong>Provincia di Residenza</strong>
            <br />
            <span> {{ contatto.provinciaResidenza }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Comune di Residenza</strong>
            <br />
            <span>{{ contatto.comuneResidenza }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Indirizzo di Residenza</strong>
            <br />
            <span>{{ contatto.indirizzoResidenza }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Cellulare</strong>
            <br />
            <span>{{ numeroCellulareAssente }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="12" lg="6" class="my-2">
            <strong>Telefono Fisso</strong>
            <br />
            <span>{{ numeroFissoAssente }}</span>
          </b-col>
        </b-row>
      </b-container>
      <div class="footer d-flex justify-content-end">
        <b-button variant="light" class="text-primary" @click="onIndietro">
          <i class="fas fa-angle-double-left"></i> Indietro
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contatto: Object,
  },
  data() {
    return {};
  },
  computed: {
    numeroCellulareAssente() {
      return this.contatto.numeroCellulare === ""
        ? "NUMERO CELLULARE ASSENTE"
        : this.contatto.numeroCellulare;
    },
    numeroFissoAssente() {
      return this.contatto.numeroFisso === ""
        ? "NUMERO TELEFONO FISSO ASSENTE"
        : this.contatto.numeroFisso;
    },
  },
  methods: {
    onIndietro() {
      let me = this;
      me.$emit("saveEdit");
    },
  },
};
</script>
