<template>
  <div>
    <lista-dipartimenti @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_DIPARTIMENTI"></lista-dipartimenti>
    <modifica-dipartimento @saveEdit="saveEdit" @onIndietro="onIndietro" v-if="step === PAGINA_MODIFICA_DIPARTIMENTO" :contatto="jsonData.contattoDaModificare"></modifica-dipartimento>
    <aggiungi-dipartimento @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_DIPARTIMENTO"></aggiungi-dipartimento>
  </div>
</template>

<script>
import ListaDipartimenti from "./listaDipartimenti.vue";
import ModificaDipartimento from "./modificaDipartimento.vue";
import AggiungiDipartimento from "./aggiungiDipartimento.vue";

export default {
  components: {
    ListaDipartimenti,
    ModificaDipartimento,
    AggiungiDipartimento,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_DIPARTIMENTI: 0,
      PAGINA_MODIFICA_DIPARTIMENTO: 1,
      // PAGINA_AS_VIEW: 2,
      PAGINA_AGGIUNGI_DIPARTIMENTO: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_DIPARTIMENTO);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_DIPARTIMENTI);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_AS_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_DIPARTIMENTO);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_DIPARTIMENTI);
    },
  },
};
</script>
