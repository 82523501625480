<template>
  <b-navbar toggleable="lg" type="dark" class="sticky-top">
    <b-navbar-brand>
      <router-link to="/pannello">
        <img alt="Logo" src="../../assets/logo-nav.png" class="h-60px" />
    </router-link>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item title="Panello">
          <router-link to="/pannello"><i class="fas fa-home fa-lg"></i> PANNELLO</router-link>
        </b-nav-item>
        <b-nav-item title="Log-out" @click="onLogOut"><i class="fas fa-sign-out-alt fa-lg"></i> ESCI</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
  </b-navbar>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
export default {
  components: { Loading },
  data() {
    return {
      linkTerminiServizio: "https://auth.ak12srl.it/ecupt/1.0.0/terminiServizio/display",
      pathResourceRichiesta: "/api/v2.0/appecupt/auth/scaricaAnagrafica",
      tokenLogin: null,
      isLoading: false,
    };
  },
  mounted: function () {
    let me = this;
    me.tokenLogin = localStorage.getItem("loginData");
  },
  methods: {
    async onLogOut() {
      let me = this;
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).includes('oidc')) {
          localStorage.removeItem(localStorage.key(i))
        }
      }
      sessionStorage.clear()
      await this.$oidc.logout()
      /*me.$router.replace(process.env.VUE_APP_REDIRECT_URI_AUTH).catch((err) => {
        err;
      });*/
    },
    onRichiestaDati() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceRichiesta;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          if (response.data.stato === 200) {
            me.isLoading = false;
            me.$bvModal
              .msgBoxOk(response.data.data, {
                title: "Richiesta Dati",
                size: "md",
                okVariant: "btn-outline",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.$emit("saveEdit");
                me.isLoading = false;
              })
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          } else {
            me.isLoading = false;
            this.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
