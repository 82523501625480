<template>
  <div>
    <div>
      <div class="mt-10 container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Domande frequenti</span></p>
        <div class="mt-15 container">
          <b-row>
            <b-col sm="2">
              <b-button class="my-2 btn-primary" v-b-modal.modalNuovaDomanda><i class="fas fa-plus"></i> Nuova domanda</b-button>
            </b-col>
          </b-row>
          <div class="container card_container">
            <b-row>
              <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                <strong class="text-primary">Lista domande</strong>
              </b-col>
            </b-row>
            <div class="accordion mt-5" role="tablist">
              <b-card class="cardDomande" no-body v-for="(f,index) in faq" v-bind:key="index">
                <b-card-header >
                  <b-row>
                    <b-col cols="10" v-b-toggle="'accordion-'+index">
                      <h6 class="toggleButton" >{{f.question}}</h6>
                    </b-col>
                    <b-col cols="1">
                      <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(f.id)">
                        <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina"></b-icon>
                      </b-button>
                    </b-col>
                    <b-col cols="1">
                      <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(f)">
                        <b-icon icon="pencil" variant="outline-secondary" data-toggle="tooltip" data-placement="bottom" title="Modifica"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-collapse :id="'accordion-'+index" :accordion="'accordion-'+index" role="tabpanel">
                  <b-card-body>
                    <b-card-text>{{f.answer}}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <modal-nuova-domanda @loadData="loadData"></modal-nuova-domanda>
        <modal-update-domanda :id="domandaSelezionataId" :item="domandaSelezionata" @loadData="loadData"></modal-update-domanda>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNuovaDomanda from "../modal/modalNuovaDomanda.vue";
import ModalUpdateDomanda from "@/components/modal/modalUpdateDomanda";
import QAs from "../../services/QAs"
import UtilityMixin from "@/utilities/utilityMixin";
export default {
  components: {
    ModalNuovaDomanda,
    ModalUpdateDomanda
  },
  data() {
    return {
      pathRestListaFaq: "/api/v2.0/faq",
      faq: [],
      domandaSelezionataId: 0,
      domandaSelezionata:
          {
            question: "",
            answer: ""
          }
    };
  },
  mounted: async function () {
    // let me = this;
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.faq = [];
      const domande = await QAs.getQas();
      if(domande.status === 200){
        for (let i=0; i<domande.data.length; i++){
          this.faq.push(domande.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },

    async onDelete(id){
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare la domanda?", {
            title: "Elimina Domanda",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const res = await QAs.deleteQas(id)
              if(res.status === 204){
                this.$bvModal
                    .msgBoxOk("Premere ok per procedere",{
                      title: "Domanda Eliminata",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadData()
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
              } else {
                this.$bvToast.toast('Riprovare più tardi', {
                  title: `Errore`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'danger',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
    async onEdit(item){
      this.domandaSelezionataId = item.id
      this.domandaSelezionata = item
      this.$bvModal.show('modalUpdateDomanda')
    }
  },
};
</script>
<style>

.cardHeader{
  display: grid;
  grid-template-columns: 1fr auto;
}

.toggleButton{
  display: block;
  text-align: left;
  background-color: transparent;
  color: black;
  border: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
/*.toggleButton:hover{*/
/*  color: red;*/
/*  background-color: transparent;*/
/*  border: none;*/
/*  box-shadow: none;*/
/*}*/

/*.toggleButton:active{*/
/*  color: red;*/
/*  background-color: transparent;*/
/*  border: none;*/
/*  box-shadow: none;*/
/*}*/

/*.toggleButton:focus{*/
/*  color: black;*/
/*  background-color: transparent;*/
/*  border: none;*/
/*  box-shadow: none;*/
/*}*/
</style>
