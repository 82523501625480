import BaseService from "./BaseService";

export default class Services extends BaseService {

   static async getServices(wardId){
      return await this.perform({
         url: '/services',
         method: 'get',
         params: {ward_id: wardId}
      })
   }

   static async getServicesById(id){
      return await this.perform({
         url: '/services',
         method: 'get',
         params: {id: id}
      })
   }

   static async createServices(body){
      return await this.perform({
         url: '/services/',
         method: 'post',
         body: body
      })
   }

   static async deleteServices(id){
      return await this.perform({
         url: '/services/',
         method: 'delete',
         params: {id: id}
      })
   }

   static async updateServices(id, body){
      return await this.perform({
         url: '/services/',
         method: 'patch',
         params: {id: id},
         body: body
      })
   }
}
