import BaseService from "./BaseService";

export default class Wards extends BaseService {

  static async getWards(departmentId){
    return await this.perform({
      url: '/wards',
      method: 'get',
      params: {department_id: departmentId}
    })
  }

  static async getWardsById(id){
    return await this.perform({
      url: '/wards',
      method: 'get',
      params: {id: id}
    })
  }

  static async createWards(body){
    return await this.perform({
      url: '/wards/',
      method: 'post',
      body: body
    })
  }

  static async deleteWards(id){
    return await this.perform({
      url: '/wards/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateWards(id, body){
    return await this.perform({
      url: '/wards/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
