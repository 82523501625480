<template>
  <div class="mt-10" style="height: 850px">
    <p class="titoloM text-center">GESTIONE CHATBOT<br/><span
        class="titoloM2">Configura i flussi di conversazione</span></p>
    <b-row>
      <b-col>
        <b-button class="my-2 btn-primary ml-5" @click="onIndietro"><i class="fas fa-cog"></i> Lista flussi</b-button>
      </b-col>
    </b-row>
    <div class="mr-2" style="height: 75%" id="div" @mouseenter="setMouseWheelZoomListener" @mouseleave="controlScale('restore')">
      <vue-tree
          ref="scaleTree"
          style="height: 100%;border: 1px solid gray"
          :dataset="flusso"
          :config="treeConfig"
          linkStyle="straight"
      >
        <template v-slot:node="{ node, collapsed }">
          <div
              class="rich-media-node"
              :style="{ border: collapsed && ((node.children !== null && node.children.length >0) || (node._children && node._children !== null && node._children.length >0)) ? '2px solid red' : '' }"
          >
            <b-row>
              <b-col xs="8" sm="8" md="8" lg="8" xl="8" style="width: 400px">
                <span style="padding: 4px 0; font-weight: bold; width: 400px">{{ node.name }}</span>
              </b-col>
              <b-col xs="4" sm="4" md="4" lg="4" xl="4" >
                <b-button size="sm" class="my-2 btn-danger ml-5 customDelete" style="float: right" variant="outline-danger" @click="onDelete(node,$event)">
                  <b-icon icon="trash" data-toggle="tooltip" data-placement="bottom" title="Elimina"></b-icon>
                </b-button>
                <b-button class="my-2 btn-primary ml-1 customHover" style="float: right" @click="openModalAggiungi(node,$event)">
                  <b-icon icon="plus-lg" font-scale="1"></b-icon>
                </b-button>
                <b-button class="my-2 btn-primary ml-1 customHover" style="float: right" @click="openModalModifica(node,$event)">
                  <b-icon icon="pencil-fill" font-scale="1"></b-icon>
                </b-button>
              </b-col>
              <b-col xs="1" sm="1" md="1" lg="1" xl="1">

              </b-col>
            </b-row>
            <span style="padding: 4px 0; width: 400px">{{node.answer}}</span>

            <br>
            <b-row>
              <b-col xs="8" sm="8" md="8" lg="8" xl="8">
                <div v-if="(node.children !== null && node.children.length >0) || (node._children && node._children !== null && node._children.length >0)">
                  <b-icon v-if="collapsed" icon="chevron-up"></b-icon>
                  <b-icon v-else icon="chevron-down"></b-icon>
                </div>
              </b-col>

            </b-row>
          </div>
        </template>
      </vue-tree>
    </div>
    <b-modal id="modalAggiungi"
             class="modal-content"
             title="Crea Flusso"
             slot="modal-header"
             hide-header-close
             size="lg"
             ok-title="Salva"
             cancel-title="Annulla"
             @ok="CreateStato"
             @cancel="resetData">
      <b-form>
            <label for="nomeStato"> <strong>Nome stato</strong></label>
            <b-form-input :state="validateNome" id="nomeStato" type="text" style="width: 40%" v-model="jsonData.name"/>
        <b-form-invalid-feedback :state="validateNome">
          Campo obbligatorio.
        </b-form-invalid-feedback>
        <b-row class="ml-1" align-v="center">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <label class="mt-5 mb-7" for="frasi"><strong>Frasi da riconoscere</strong></label>
          </b-col>
          <b-col xs="1" sm="1" md="1" lg="1" xl="1">
            <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addFrase" data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: left">
              <b-icon icon="plus" variant="outline-secondary" />
            </b-button>
          </b-col>
        </b-row>
        <div id="frasi" v-for="(frasi, index) in jsonData.sentences" :key="index">
          <div class="frasiGrid">
            <b-row>
              <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                <b-form-input type="textarea" v-model="frasi.text" class="mb-5"></b-form-input>
              </b-col>
              <b-col xs="1" sm="1" md="1" lg="1" xl="1">
                <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteFrase(index)" data-toggle="tooltip" data-placement="bottom" title="Elimina" style="float: right">
                  <b-icon icon="trash" variant="outline-secondary" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <label for="risposta"><strong>Risposta</strong></label>
        <b-form-input :state="validateRisposta" id="risposta" type="text" style="width: 40%" v-model="jsonData.answer"/>
        <b-form-invalid-feedback :state="validateRisposta">
          Campo obbligatorio.
        </b-form-invalid-feedback>
        <b-row class="ml-1" align-v="center">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <label class="mt-5 mb-7" for="scelte"><strong>Scelte (opzionale)</strong></label>
          </b-col>
          <b-col xs="1" sm="1" md="1" lg="1" xl="1">
            <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addScelte" data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: left">
              <b-icon icon="plus" variant="outline-secondary" />
            </b-button>
          </b-col>
        </b-row>
        <div id="scelte" v-for="(scelte, index1) in jsonData.options" :key="index1+10000">
          <div class="frasiGrid">
            <b-row>
              <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                <b-form-input type="text" v-model="scelte.value" class="mb-5"></b-form-input>
              </b-col>
              <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                <b-form-select v-model="scelte.next_state" class="mb-5" :options="stateOptions" text-field="name" value-field="name"></b-form-select>
              </b-col>
              <b-col xs="1" sm="1" md="1" lg="1" xl="1">
                <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteScelta(index1)" data-toggle="tooltip" data-placement="bottom" title="Elimina" style="float: right">
                  <b-icon icon="trash" variant="outline-secondary" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import States from "@/services/States";
import UtilityMixin from "@/utilities/utilityMixin";

export default {
  data() {
    return {
      stateOptions: [],
      modifica: false,
      jsonData: {
        name: '',
        sentences: [
          {text: ''}
        ],
        answer: '',
        parent_id: null,
        options: [
          {
            value: null,
            next_state: null
          }
        ]
      },
      hiddenCanvas: null,
      vehicules: {
        name: 'Wheels',
        children: [
          {
            name: 'Wings',
            children: [
              {
                name: 'Plane'
              }
            ]
          },
          {
            name: 'Piston',
            customID: 3
          },
          {
            name: 'Carburetor asdasfas ef awdf asf  asfd  asdfasdasd',
            children: [
              {
                name: 'Truck',
                customID: 2
              },
              {
                name: 'Car',
                customID: 2,
                children: [
                  {
                    name: 'prova',
                    children: [
                      {
                        name: 'prova',
                        children: [
                          {
                            name: 'prova',
                            children: [
                              {
                                name: 'prova',
                                children: [
                                  {
                                    name: 'prova'
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'Valve',
            customID: 4
          },
          {
            name: 'Crankshaft',
            customID: 1
          }
        ],
        links: [
          { parent: 1, child: 2 },
          { parent: 3, child: 2 },
          { parent: 4, child: 2 },
          {}
        ],
        identifier: 'customID'
      },
      flusso: null,
      treeConfig: { nodeWidth: 600, nodeHeight:100, levelHeight: 200 }
    }
  },
  computed: {
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    },
    validateRisposta(){
      if(this.jsonData.answer === undefined)
        return false
      return this.jsonData.answer.length > 0;
    },
  },
  mounted: async function(){
    await this.getFlusso()
  },
  methods: {
    controlScale(command) {
      switch (command) {
        case 'bigger':
          this.$refs.scaleTree.zoomIn()
          break
        case 'smaller':
          this.$refs.scaleTree.zoomOut()
          break
        case 'restore':
          this.$refs.scaleTree.restoreScale()
          break
      }
    },

    async getFlusso(){
      const response = await States.getFlow(this.$router.currentRoute.params.id)
      if(response.status === 200){
        this.flusso = response.data[0]
        console.log(this.flusso)
      } else {
        this.$bvToast.toast("Non sono presenti Flussi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },

    setMouseWheelZoomListener() {
      document.getElementById('div').addEventListener('wheel', (event) => {
        event.preventDefault()
        event.stopImmediatePropagation()
        if (event.deltaY < 0) {
          this.controlScale('bigger')
        } else {
          this.controlScale('smaller')
        }
      })
    },
    async openModalAggiungi(item, event) {
      this.resetData()
      event.stopPropagation()
      console.log(item)
      this.jsonData.parent_id = item.id
      const response = await States.getAllState()
      if(response.status === 200){
        console.log(response)
        this.stateOptions = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Stati.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
      this.modifica = false
      this.$bvModal.show('modalAggiungi')
    },
    async openModalModifica(item, event) {
      event.stopPropagation()
      console.log(item)
      const response = await States.getAllState()
      if(response.status === 200){
        console.log(response)
        this.stateOptions = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Stati.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
      this.modifica = true
      this.jsonData = {...item}
      this.$bvModal.show('modalAggiungi')
    },
    addFrase(){
      if ( this.jsonData.sentences === null ) {
        this.jsonData.sentences = [{text: null}]
      } else
        this.jsonData.sentences.push({text: null})
    },
    deleteFrase(index){
      this.jsonData.sentences.splice(index, 1)
    },
    addScelte(){
      if(this.jsonData.options === null) {
        this.jsonData.options = [{value: null, next_state: null}]
      } else
        this.jsonData.options.push({value: null, next_state: null})
    },
    deleteScelta(index){
      this.jsonData.options.splice(index, 1)
    },
    async CreateStato(bvModalEvent){
      bvModalEvent.preventDefault()
      if( this.validateNome && this.validateRisposta) {
        if(this.jsonData.options != null){
          for(let i=0; i<this.jsonData.options.length; i++){
            if(this.jsonData.options[i].next_state === null || this.jsonData.options[i].value === null){
              this.jsonData.options.splice(i, 1)
            }
          }
        }
        if( this.jsonData.sentences != null ) {
          for(let i=0; i<this.jsonData.sentences.length; i++){
            if(this.jsonData.sentences[i].text === null || this.jsonData.sentences[i].text === ''){
              this.jsonData.sentences.splice(i, 1)
            }
          }
        }
        if(this.modifica === true){
          const response = await States.updateState(this.jsonData, this.jsonData.id)
          if(response.status === 204){
            this.$bvToast.toast("Flusso modificato con successo.", {
              title: `Successo`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'success',
              noCloseButton: true
            })
            console.log(response)
            this.jsonData = {
              name: '',
              sentences: [
                {text: ''}
              ],
              answer: '',
              parent_id: null,
              options: [
                {
                  value: "",
                  next_state: ""
                }
              ]
            }
            await this.getFlusso()
          } else {
            this.$bvToast.toast("Non è stato possibile modificare il Flusso.", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'warning',
              noCloseButton: true
            })
          }
        } else {
          const response = await States.createState(this.jsonData)
          if(response.status === 204){
            this.$bvToast.toast("Flusso aggiunto con successo.", {
              title: `Successo`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'success',
              noCloseButton: true
            })
            console.log(response)
            this.jsonData = {
              name: '',
              sentences: [
                {text: ''}
              ],
              answer: '',
              parent_id: null,
              options: [
                {
                  value: "",
                  next_state: ""
                }
              ]
            }
            await this.getFlusso()
          } else {
            this.$bvToast.toast("Non è stato possibile creare il Flusso.", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'warning',
              noCloseButton: true
            })
          }
        }
        this.$nextTick(() => {
          this.$bvModal.hide('modalAggiungi')
        })
      } else {
        this.$bvToast.toast("Errore. Bisogna compilare tutti i campi necessari.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    resetData(){
      this.jsonData = {
        name: '',
        sentences: [
          {text: ''}
        ],
        answer: '',
        parent_id: null,
        options: [
          {
            value: "",
            next_state: ""
          }
        ]
      }
    },
    async onDelete(item, event){
      event.stopPropagation()
      this.$bvModal
          .msgBoxConfirm(`Sei sicuro di voler eliminare lo stato "${item.name}"?`, {
            title: "Elimina Stato",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const response = await States.deleteState(item.id)
              if(response.status === 204){
                this.$bvToast.toast("Eliminazione avvenuta con successo.", {
                  title: `Successo`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'success',
                  noCloseButton: true
                })
                await this.getFlusso()
              } else {
                this.$bvToast.toast("Non è stato possibile eliminare lo stato. Riprovare più tardi.", {
                  title: `Attenzione`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'warning',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
    onIndietro(){
      this.$router.push('/listachatbot')
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  padding: 8px;
  display: block !important;
  color: white;
  background-color: #094c8d;
  border-radius: 4px;
}

.customHover:hover{
  background-color: white;
  color: #094c8d;
}

.customDelete{
  color: white;
}
.customDelete:hover{
  background-color: #FF001AE2;
}
</style>
