import vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login/login.vue";
import registrati from "../components/registrati/registrati.vue";
import contatti from "../components/contatti/contatti.vue";
import contattaci from "../components/supporto/contattaci.vue";
import profilo from "../components/anagrafica/utente.vue";
import pagamentoConfermato from "../components/pagamento/pagamentoConfermato.vue";
import errorePagamento from "../components/pagamento/errorePagamento.vue";
import storicoPagamenti from "../components/pagamento/storicoPagamenti.vue";
import home from "../components/home/home.vue"
import main from '../components/main/main.vue';
import strutture from '../components/strutture/pannelloStrutture.vue';
import dottori from '../components/dottori/dottori.vue';
import faq from '../components/supporto/faq.vue';
import aziendeSanitarie from '../components/aziendaSanitaria/as.vue';
import ospedali from '../components/ospedali/ospedali.vue';
import dipartimenti from '../components/dipartimenti/dipartimenti.vue';
import reparti from '../components/reparti/reparti.vue';
import branca from '../components/branca/branca.vue';
import servizi from '../components/servizi/servizi.vue';
import patologiaEcura from '../components/patologiaCura/patologiaCura.vue';
import gestioneChatbot from "@/components/gestioneChatbot/gestioneChatbot";
import listaChatbot from "@/components/gestioneChatbot/listaChatbot";

vue.use(VueRouter);

let mainChildren = [

  {
    path: "/profilo",
    name: "Profilo",
    component: profilo,
  },
  {
    path: "/pannello",
    name: "Pannello",
    component: home,
  },
  {
    path: '/contattaci',
    name: 'contattaci',
    component: contattaci
  },
  {
    path: '/pagamentoconfermato',
    name: 'pagamentoconfermato',
    component: pagamentoConfermato,
  },
  {
    path: '/errorepagamento',
    name: 'errorepagamento',
    component: errorePagamento,
  },
  {
    path: '/storicopagamenti',
    name: 'storicopagamenti',
    component: storicoPagamenti,
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: contatti
  },
  {
    path: '/strutture',
    name: 'strutture',
    component: strutture
  },
  {
    path: '/dottori',
    name: 'dottori',
    component: dottori
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq
  },
  {
    path: '/listachatbot',
    name: 'listachatbot',
    component: listaChatbot
  },
  {
    path: '/gestionechatbot/:id',
    name: 'gestioneChatbot',
    component: gestioneChatbot
  },
  {
    path: '/aziendesanitarie',
    name: 'aziendesanitarie',
    component: aziendeSanitarie
  },
  {
    path: '/ospedali',
    name: 'ospedali',
    component: ospedali
  },
  {
    path: '/dipartimenti',
    name: 'dipartimenti',
    component: dipartimenti
  },
  {
    path: '/reparti',
    name: 'reaprti',
    component: reparti
  },
  {
    path: '/branca',
    name: 'branca',
    component: branca
  },
  {
    path: '/servizi',
    name: 'servizi',
    component: servizi
  },
  {
    path: '/patologia',
    name: 'patologia',
    component: patologiaEcura
  }
];

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: 'home',
    component: main,
    children: mainChildren,
  },
  {
    path: '/registrati',
    name: 'Registrati',
    component: registrati,
  },
]

const router = new VueRouter({
  routes
})

export default router
