<template>
  <div>
    <navbar2></navbar2>
    <modal-3></modal-3>
    <modal-4></modal-4>
    <d-stepper :steps="steps">
      <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
    </d-stepper>
  </div>
</template>
<script>
import Navbar2 from "../navbar/navbar2.vue";
import Modal3 from "../modal/modal3.vue";
import Modal4 from "../modal/modal4.vue";
import Passo1 from "./Passo1.vue";
import Passo2 from "./Passo2.vue";
import Passo3 from "../registrati/Passo3.vue";
import DStepper from "../stepper/DStepper.vue";

export default {
  components: {
    Navbar2,
    Modal3,
    Modal4,
    DStepper,
  },
  data() {
    return {
      steps: [
        {
          name: "Dati Personali",
          disabled: false,
          active: true,
          component: Passo1,
        },
        {
          name: "Dati Account",
          disabled: false,
          active: false,
          component: Passo2,
        },
        {
          name: "Conferma",
          disabled: false,
          active: false,
          component: Passo3,
          confirm: "Registrati",
        },
      ],
    };
  },
};
</script>
