<template>
  <div>
    <div class="mt-10 container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br/><span class="titoloM2">Benvenuto amministratore</span></p>
    </div>
    <div class="mt-15 container">
      <b-row margi>
        <!--<b-col sm>
          <div class="card_pic mb-5 mt-20">
            <p class="text-center"><img alt="nuovo utente" src="../../assets/nuovo_amministratore.svg" class="h-150px"/>
            </p>
            <p class="text-center">Nuovo amministratore</p>
          </div>
        </b-col>-
        <b-col sm>
          <div class="card_pic mb-5 mt-20">
            <p class="text-center" @click="onStrutture()"><img alt="nuovo utente" src="../../assets/info_struttura.svg" class="h-150px"/></p>
            <p class="text-center">Gestione base di conoscenza</p>
          </div>
        </b-col>
        <b-col sm>
          <div class="card_pic mb-5 mt-20" @click="onFaq()" >
            <p class="text-center"><img alt="nuovo utente" src="../../assets/info_non_strutturate.svg" class="h-150px"/>
            </p>
            <p class="text-center">Domande frequenti</p>
          </div>
        </b-col>-->
        <b-col sm>
          <div class="card_pic mb-5 mt-20" @click="onChatbot()" >
            <p class="text-center"><img alt="nuovo utente" src="../../assets/info_non_strutturate.svg" class="h-150px"/>
            </p>
            <p class="text-center">Gestione chatbot</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      jsonData: {
        assistito: {
          provinciaResidenza: null,
        },
      },
    };
  },
  computed: {},
  mounted: function () {
  },
  methods: {
    getInfoUtente(item) {
      let me = this;
      me.jsonData.assistito = item;
    },
    onUtente() {
      let me = this;
      me.$router.push("/contatti");
    },
    onFaq() {
      let me = this;
      me.$router.push("/faq");
    },
    onStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    onChatbot(){
      this.$router.push("/listachatbot")
    }
  },
};
</script>

<style scoped></style>
