<template>
  <div>
    <div class="mt-15 container card_container">
      <h3 class="mb-5 titolo"><i class="fas fa-at"></i> Supporto</h3>
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mb-7 mt-5">
            <div class="h-35px">
              <strong class="text-primary">Assistito</strong>
            </div>
            <b-form-select class="form-select" v-model="jsonData.assistito" :options="assistitoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm class="mb-7">
            <span class="text-primary">Messaggio</span><br />
            <b-form-textarea v-model="jsonData.messaggio" debounce="500" class="form-control"></b-form-textarea>
          </b-col>
        </b-row>
      </b-container>
      <div class="footer d-flex justify-content-end">
        <b-button variant="outline-success" class="ml-2 btn-outline" @click="onInvia" :disabled="invioDisabilitato"> <i class="fas fa-paper-plane"></i> Invia </b-button>
      </div>
    </div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import UtilityMixin from "../../utilities/utilityMixin.js";

export default {
  mixins: [UtilityMixin],
  components: {
    Loading,
  },
  data() {
    return {
      pathResourceInfoUtente: "/api/v2.0/appecupt/auth/prelevaAnagraficaUtente",
      pathResourceContatti: "/api/v2.0/appecupt/auth/me",
      pathResourceInviaMessaggio: "/urlserviziapp/inviaMailAssistenza",
      jsonData: {
        assistito: {},
      },
      isLoading: false,
      tokenLogin: null,
      token: null,
      invioDisabilitato: true,
      assistitoOptions: [{ value: null, text: "Seleziona Assistito", disabled: true }],
      struttureSelezionate: [],
    };
  },
  mounted: function () {
    let me = this;
    me.tokenLogin = localStorage.getItem("loginData");
    me.token = localStorage.getItem("tokenWSO2");
    me.loadInfoUtente();
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceContatti;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            struttura: "150021",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.isLoading = false;
          response.data.data.forEach((element) => {
            me.assistitoOptions.push({
              value: element,
              text: `${element.nome} ${element.cognome} - ${element.codiceFiscale}`,
            });
          });
        })
        .catch((error) => {
          me.isLoading = false;
          error;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
        });
    },
    loadInfoUtente() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceInfoUtente;
      me.isLoading = true;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          me.isLoading = false;
          let infoUtente = response.data.data;
          me.assistitoOptions.push({
            value: infoUtente,
            text: `${infoUtente.nome} ${infoUtente.cognome} - ${infoUtente.codiceFiscale}`,
          });
          me.jsonData.assistito = response.data.data;
        })
        .catch((error) => {
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          error;
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    onInvia() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceInviaMessaggio;
      me.isLoading = true;
      axios
        .post(
          link,
          {
            codiceFiscale: me.jsonData.assistito.codiceFiscale,
            messaggio: me.jsonData.messaggio,
            strutture: me.struttureSelezionate,
          },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
              "x-access-token": me.tokenLogin,
            },
          }
        )
        .then((response) => {
          if (response.data.stato === 200) {
            me.isLoading = false;
            me.$bvModal
              .msgBoxOk(response.data.data, {
                title: "Invio Richiesta Supporto",
                size: "md",
                okVariant: "outline-success",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.isLoading = false;
              })
              .catch((error) => {
                me.isLoading = false;
                console.log(error);
              });
          } else {
            me.isLoading = false;
            me.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "md",
                okVariant: "outline-success",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.isLoading = false;
              })
              .catch((error) => {
                me.isLoading = false;
                console.log(error);
              });
          }
        })
        .catch((error) => {
          me.isLoading = false;
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          console.log(error);
        });
    },
    setStruttura(item) {
      let me = this;
      me.struttureSelezionate = {};
      me.struttureSelezionate = item;
    },
  },
  watch: {
    "jsonData.messaggio": function (value) {
      if (value.length !== 0) {
        this.invioDisabilitato = false;
      } else {
        this.invioDisabilitato = true;
      }
    },
  },
};
</script>
