<template>
  <div class="mt-15 container">
    <div>
      <b-row>
        <b-col sm="2">
          <b-button class="my-2 btn-primary" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong class="text-primary">Nome</strong></div>
              <b-form-input type="text" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.nome" />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Cognome</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cognome" placeholder="Cognome" value="" v-model.trim="jsonData.cognome" />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Codice fiscale</strong>
              </div>
              <b-form-input
                type="text"
                class="form-control form-control-solid"
                name="cf"
                placeholder="Codice Fiscale"
                value=""
                v-model="jsonData.codiceFiscale"
                :state="validationCodiceFiscale"
                maxlength="16"
                style="text-transform: uppercase"
                @keydown.space.prevent
              />
            </b-col>
          </b-row>
          <!-- <div v-if="codiceFiscaleInserito"> -->
          <div>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Username</strong></div>
                <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Username" value="" v-model.trim="jsonData.username" />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Password</strong>
                </div>
                <b-form-input type="text" class="form-control form-control-solid" name="password" placeholder="Password" value="" v-model.trim="jsonData.password" />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Ripeti Password</strong>
                </div>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid"
                  name="cf"
                  placeholder="Ripeti Password"
                  value=""
                  v-model="jsonData.ripetiPassword"
                  :state="validationCodiceFiscale"
                  maxlength="16"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">E-mail</strong>
                </div>
                <b-form-input
                  type="email"
                  class="form-control form-control-solid"
                  name="email"
                  placeholder="Email"
                  value=""
                  v-model.trim="jsonData.email"
                  required
                  :state="validationEmail"
                  @keydown.space.prevent
                />
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary"> Permessi</strong>
                </div>
                <b-form-select class="form-select" v-model="jsonData.permessi" :value="null" value-field="value" :options="permessiOptions" text-field="text"></b-form-select>
              </b-col>
            </b-row>
          </div>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onAggiungi"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceListaProvince: "/api/v2.0/appecupt/comuni/listaprovince",
      pathResourceStatoCivile: "/api/v2.0/appecupt/statocivile",
      pathResourceListaComuni: "/api/v2.0/appecupt/comuni/listacomuni",
      pathResourceListaNazioni: "/api/v2.0/appecupt/nazioni/",
      pathResourceModificaContatto: "/api/v2.0/appecupt/auth/modAssistito",
      pathResourceConvalidaCF: "/api/v2.0/appecupt/codicefiscale/codicefiscaleinverso?scenario=1",
      pathResourceAggiungiContatto: "/api/v2.0/appecupt/contatti/aggiungiContatto",
      jsonData: {
        codiceFiscale: "",
        codiceTS: "",
        numeroCellulare: "",
        numeroFisso: "",
        codiceNazionalita: "",
        controlloStep1: true,
        controlloStep2: true,
        controlloStep3: true,
        provinciaResidenza: null,
        comuneResidenza: null,
        email: "",
        permessi: null,
      },
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [{ value: null, text: "Seleziona la Provincia", disabled: true }],
      permessiOptions: [{ value: null, text: "Seleziona i Permessi", disabled: true }],
      cittadinanzaOptions: [{ value: null, text: "Seleziona la cittadinanza", disabled: true }],
      comuniResidenzaOptions: [
        { value: null, text: "Seleziona il Comune", disabled: true },
        {
          value: {
            cap: this.capResidenza,
            codice: this.codIstatComuneResidenza,
            nome: this.comuneResidenza,
            provinciaSigla: this.provinciaResidenzaSigla,
          },
          text: this.comuneResidenza,
        },
      ],
      statoCivileOptions: [{ value: null, text: "Seleziona Stato Civile", disabled: true }],

      token: null,
      nazionalitaStraniera: false,
      isLoading: false,
      codiceFiscaleInserito: false,
      disableComuneResidenza: true,
    };
  },
  computed: {
    validationCodiceFiscale() {
      var regexCodiceFiscale =
        /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
      return this.jsonData.codiceFiscale.length === 0 ? null : regexCodiceFiscale.test(this.jsonData.codiceFiscale) ? true : false;
    },
    validationCellulare() {
      var regexCellulare = /^3\d{8,9}$/;

      return regexCellulare.test(this.jsonData.numeroCellulare) ? true : false;
    },
    validationFisso() {
      let regexFisso = /^0\d+$/;
      return !this.jsonData.numeroFisso || this.jsonData.numeroFisso.length === 0 ? null : regexFisso.test(this.jsonData.numeroFisso) ? true : false;
    },
    validationEmail() {
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.jsonData.email.length === 0 ? null : regexEmail.test(this.jsonData.email) ? true : false;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.tokenLogin = localStorage.getItem("loginData");
    me.loadListaProvince();
    me.loadStatoCivile();
  },
  watch: {
    "jsonData.codiceFiscale": function (value) {
      this.jsonData.codiceFiscale = value.toUpperCase();
    },
    "jsonData.codiceTS": function () {
      if (this.jsonData.codiceTS.length === 5 && this.validationCodiceFiscale) this.loadInfoContatto();
    },
  },
  methods: {
    loadInfoContatto() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceConvalidaCF;
      me.isLoading = true;
      axios
        .post(link, me.jsonData, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          if (response.data.stato === 200 || response.data.stato === 201) {
            console.log(response.data.data.errorCodiceTS);
            if (response.data.data.errorCodiceTS) {
              me.isLoading = false;
              this.$bvModal
                .msgBoxOk("I 5 digit non corrispondono", {
                  title: "Errore",
                  size: "md",
                  okVariant: "outline-danger",
                  headerClass: "vv-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {
                  me.$emit("onIndietro");
                  me.isLoading = false;
                })
                .catch((err) => {
                  me.isLoading = false;
                  console.log(err);
                });
            } else {
              me.jsonData = response.data.data;
              me.codiceFiscaleInserito = true;
              me.controlloNazionalita();
              me.isLoading = false;
            }
          } else {
            this.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
            me.isLoading = false;
          }
        })
        .catch((error) => {
          me.isLoading = false;
          console.log(error);
        });
    },
    loadListaProvince() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaProvince;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.provinciaResidenzaOptions.push({
              value: element,
              text: element.provincia.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadStatoCivile() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceStatoCivile;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.statoCivileOptions.push({
              value: element,
              text: element.descrizione.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedProvinciaResidenza(provinciaResidenza) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaComuni;
      me.comuniResidenzaOptions = [{ value: null, text: "Seleziona il Comune", disabled: true }];
      axios
        .post(
          link,
          { codIstat: provinciaResidenza.codIstat },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            me.comuniResidenzaOptions.push({
              value: element,
              text: element.nome.toUpperCase(),
            });
          });
          me.disableComuneResidenza = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    controlloNazionalita() {
      let me = this;
      if (me.jsonData.codiceFiscale != null && me.jsonData.codiceNazionalita !== "100") me.nazionalitaStraniera = true;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaNazioni;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.cittadinanzaOptions.push({
              value: element,
              text: element.descrizione,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onAggiungi() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceAggiungiContatto;
      const datiContatto = {
        codiceFiscale: me.jsonData.codiceFiscale,
        codiceTS: me.jsonData.codiceTS,
        controlloStep2: me.jsonData.controlloStep2,
        controlloStep3: me.jsonData.controlloStep3,
        nome: me.jsonData.nome,
        cognome: me.jsonData.cognome,
        dataNascita: me.jsonData.dataNascita,
        sesso: me.jsonData.sesso,
        scadenzaTS: me.jsonData.scadenzaTS,
        luogoDiNascita: me.jsonData.luogoDiNascita,
        istatLuogoDiNascita: me.jsonData.istatLuogoDiNascita,
        controlloStep1: me.jsonData.controlloStep1,
        statoCivile: me.jsonData.statoCivile.descrizione,
        codStatoCivile: me.jsonData.statoCivile.id,
        provinciaResidenza: me.jsonData.provinciaResidenza.provincia,
        codIstatProvinciaResidenza: me.jsonData.provinciaResidenza.codIstat,
        comuneResidenza: me.jsonData.comuneResidenza.nome,
        codIstatComuneResidenza: me.jsonData.comuneResidenza.codice,
        provinciaResidenzaSigla: me.jsonData.comuneResidenza.provinciaSigla,
        strutturaPreferita: "150901",
        istatCittadinanza: me.jsonData.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.cittadinanza.istat : me.jsonData.codiceNazionalita,
        indirizzoResidenza: me.jsonData.indirizzoResidenza,
        capResidenza: me.jsonData.comuneResidenza.cap,
        cittadinanza: me.jsonData.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.cittadinanza.descrizione : "ITALIA",
        terminiServizio: me.jsonData.terminiServizio,
        numeroCellulare: me.jsonData.numeroCellulare,
        numeroFisso: me.jsonData.numeroFisso,
        codiceNazionalita: me.jsonData.codiceNazionalita,
      };
      me.isLoading = true;
      axios
        .post(link, datiContatto, {
          headers: {
            versioneapp: "5",
            Authorization: `Bearer ${me.token}`,
            "x-access-token": me.tokenLogin,
          },
        })
        .then((response) => {
          if (response.data.stato === 201) {
            me.isLoading = false;
            this.$bvModal
              .msgBoxOk(response.data.data.messaggioSuccessoRegistrazione, {
                title: "Congratulazioni",
                size: "md",
                okVariant: "outline-success",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.$emit("onIndietro");
                me.isLoading = false;
              })
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          } else {
            this.$bvModal
              .msgBoxOk(response.data.errore, {
                title: "Errore",
                size: "md",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
            me.isLoading = false;
          }
        })
        .catch((err) => {
          err;
        });
    },
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
  },
};
</script>
