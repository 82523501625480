<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Nuova branca</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Codice</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice" value="" v-model="jsonData.code" maxlength="16" />
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Nome</strong>
              </div>
              <b-form-input type="text" :state="validateNome" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.name" />
              <b-form-invalid-feedback :state="validateNome">
                Campo obbligatorio.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSalva"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Branches from "@/services/Branches";
export default {
  components: { Loading },
  data() {
    return {
      as: null,
      hospital_id: null,
      department_id: null,

      jsonData: {
        code: "",
        name: "",
        ward_id: null,
      },
      isLoading: false,
      disableComuneResidenza: true,
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      repartoOptions: [],
    };
  },
  computed: {
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    }
  },
  mounted: async function () {},

  methods: {
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    async onSalva() {
      if(this.validateNome){
        const result = await Branches.createBranches(this.jsonData)
        if(result.status === 204){
          this.$emit("onIndietro");
        } else {
          console.log(result)
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }
    }
  },
};
</script>
