import axios from "axios";

// let HttpRequest = {
//   url,
//   method: "get" | "post" | "put" | "delete" | "GET" | "POST" | "PUT" | "DELETE",
//   auth,
//   body,
//   params,
//   token
// };
//
//
// let APIResponse = {
//   error,
//   code,
//   msg,
//   data
// };

export default class BaseService {

  static async perform(request){
    try{
      const config = {
        baseURL: process.env.VUE_APP_URL_BACKEND,
        url: request.url,
        method: request.method,
        headers: {
          "Accept-Version": "1.0.0"
        },
        data: request.body,
        params: request.params
      };

      // if (request.auth) {
      //     config.headers!["Authorization"] = `Bearer ${request.token ?? localStorage.getItem("access-token")}`;
      // }
      if( sessionStorage.getItem("access_token") !== null)
        config.headers["authorization"] = "Bearer " + sessionStorage.getItem("access_token");


      const response = (await axios(config));
      setTimeout(()=>{
        if(response.error && response.code === 401){
          sessionStorage.removeItem("access_token")
          location.href = '/';
        }},2000)
      return response;
    } catch (err) {
      if(err.response) {
        console.log(err.response.data, err.response.status, err.response.headers);
        return err;
      } else {
        console.log(err);
        return err
      }
    }
}

}
