<template>
  <b-modal
    size="xl"
    id="modalNuovaDomanda"
    centered
    title="Nuova domanda"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    ref="modalNuovaDomanda"
    ok-title="Salva"
    cancel-title="Annulla"
    @ok="onSalva"
    @hidden="onClose"
  >
    <b-row>
      <b-col sm class="mb-7 mt-5">
        <div class="h-35px"><strong class="text-primary">Domanda</strong></div>
        <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Domanda" value="" v-model.trim="jsonData.question" :state="validationDomanda"/>
        <b-form-invalid-feedback :state="validationDomanda">
          Il campo non può essere vuoto.
        </b-form-invalid-feedback>
      </b-col>
      <b-col sm class="mb-7 mt-5">
        <div class="h-35px">
          <strong class="text-primary">Risposta</strong>
        </div>
        <b-form-input type="text" class="form-control form-control-solid" name="password" placeholder="Risposta" value="" v-model.trim="jsonData.answer" :state="validationRisposta"/>
        <b-form-invalid-feedback :state="validationRisposta">
          Il campo non può essere vuoto.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import QAs from "@/services/QAs";
export default {
  data() {
    return {
      jsonData: {
        question: "",
        answer: "",
      },
    };
  },
  computed: {
    validationDomanda(){
      return this.jsonData.question.length > 0
    },
    validationRisposta(){
      return this.jsonData.answer.length > 0
    }
  },
  methods: {
    show() {
      this.$refs["modalNuovaDomanda"].show();

    },
    async onSalva() {
      if(this.validationDomanda && this.validationRisposta){
        const response = await QAs.createQas(this.jsonData)
        if(response.status === 204){
          this.$emit("loadData")
          this.$emit("onChiudi");
        } else {
          this.$bvToast.toast('Riprovare più tardi', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    async onClose(){

    }
  },
};
</script>
