import BaseService from "./BaseService";

export default class AuthService extends BaseService {

    static async getToken(code, codeVerifier){
        return await this.perform({
            url:'/auth/',
            method: 'post',
            body: {
                grant_type:"authorization_code",
                code: code,
                redirect_uri:"https://cb-fe-devel.cdpregs-sanita-coll.cs1.cloudspc.it",
                code_verifier: codeVerifier,
                client_id:"chatbot"
            }
        })
    }
}
