<template>
  <div class="mt-15 container card_container">
    <b-container class="bv-example-row">
      <b-card bg-variant="light">
        <h3 class="mb-5 titolo-danger"><i class="fas fa-exclamation-triangle"></i> Pagamento Non Riuscito</h3>
        <b-container class="bv-example-row pt-lg-6">
          <b-row class="text-center">
            <b-col sm class="mb-7">
              <strong><span class="text-center">A causa di un errore il pagamento è stato respinto</span></strong>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tokenLogin: null,
    };
  },
  mounted: function () {
    let me = this;
    me.tokenLogin = localStorage.getItem("loginData");
    me.controlloToken();
  },
  methods: {
    controlloToken() {
      let me = this;
      if (me.tokenLogin) {
        setTimeout(() => me.$router.push({ path: "/listapendenzenre" }), 5000);
      }
    },
  },
};
</script>
