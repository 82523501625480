<template>
  <div>
    <div class="separator border-4 my-3"></div>
    <b-container class="bv-example-row">
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Nome</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="nome"
            placeholder="Nome"
            value=""
            v-model="jsonData.nome"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Cognome</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cognome"
            placeholder="Cognome"
            value=""
            v-model="jsonData.cognome"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Codice fiscale</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="cf"
            placeholder="Codice Fiscale"
            value=""
            v-model="jsonData.codiceFiscale"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Data di nascita</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="datanascita"
            placeholder="Data di nascita"
            value=""
            v-model="jsonData.dataNascita"
            locale="it"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Provincia di nascita</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            value=""
            v-model="jsonData.provincia"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Comune di nascita</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="comunenascita"
            placeholder="Comune di nascita"
            value=""
            v-model="jsonData.luogoDiNascita"
            style="text-transform: uppercase"
            disabled
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Sesso</strong>
          </div>
          <b-form-select class="form-select" v-model="jsonData.sesso" :options="sessoOptions" disabled> </b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Stato civile</strong>
          </div>
          <b-form-select class="form-select" v-model="jsonData.statoCivile" :options="statoCivileOptions" :value="null"></b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Provincia di residenza</strong>
          </div>
          <b-form-select
            class="form-select"
            v-model="jsonData.provinciaResidenza"
            :options="provinciaResidenzaOptions"
            :value="null"
            value-field="value"
            text-field="text"
            @change="getSelectedProvinciaResidenza"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="nazionalitaStraniera">
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Nazionalità</strong>
          </div>
          <b-form-select class="form-select" v-model="jsonData.cittadinanza" :options="cittadinanzaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong> Comune di residenza</strong>
          </div>
          <b-form-select
            class="form-select"
            v-model="jsonData.comuneResidenza"
            :value="null"
            value-field="value"
            :options="comuniResidenzaOptions"
            text-field="text"
            style="text-transform: uppercase"
          ></b-form-select>
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Indirizzo di residenza</strong>
          </div>
          <b-form-input type="text" v-model="jsonData.indirizzoResidenza" :value="null" value-field="value" text-field="" style="text-transform: uppercase"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Cellulare</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            placeholder="Cellulare"
            value=""
            required
            maxlength="10"
            :state="validationCellulare"
            v-model="jsonData.numeroCellulare"
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Telefono fisso</strong>
          </div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            placeholder="Telefono fisso"
            value=""
            required
            maxlength="10"
            :state="validationFisso"
            v-model="jsonData.numeroFisso"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Inserisci le ultime 5 cifre della tessera sanitaria</strong>
          </div>
          <input
            type="text"
            class="form-control form-control-solid"
            name="provincianascita"
            placeholder="ultime 5 cifre della tessera sanitaria"
            value=""
            v-model="jsonData.codiceTS"
            disabled
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Data di scadenza</strong></div>
          <b-form-input
            type="text"
            class="form-control form-control-solid"
            name="datascadenza"
            placeholder="Data di scadenza"
            value=""
            v-model="jsonData.scadenzaTS"
            size="sm"
            locale="it"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-form-checkbox id="checkbox-1" v-model="jsonData.terminiServizio" name="checkbox-1" :value="true" :unchecked-value="false">
          <span class="ml-2">
            Prima di continuare accettare
            <a :href="linkTerminiServizio" target="_blank" class="link-termini">termini e condizioni</a></span
          >
        </b-form-checkbox>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {
          statoCivile: null,
          numeroCellulare: "",
          numeroFisso: "",
          provincia: "",
          provinciaResidenza: null,
          comuneResidenza: null,
          indirizzoResidenza: "",
          luogoDiNascita: "",
          nome: "",
          cognome: "",
          codiceFiscale: "",
          dataNascita: "",
          sesso: "",
          terminiServizio: null,
          scadenzaTS: "",
          codiceTS: "",
        };
      },
    },
  },
  data() {
    return {
      statoCivileOptions: [{ value: null, text: "Seleziona Stato Civile", disabled: true }],
      sessoOptions: [
        { value: "M", text: "MASCHIO" },
        { value: "F", text: "FEMMINA" },
      ],
      provinciaResidenzaOptions: [{ value: null, text: "Seleziona la Provincia", disabled: true }],
      cittadinanzaOptions: [{ value: null, text: "Seleziona la cittadinanza", disabled: true }],
      comuniResidenzaOptions: [{ value: null, text: "Seleziona il Comune", disabled: true }],
      linkTerminiServizio: "https://auth.ak12srl.it/ecupt/1.0.0/terminiServizio/display",
      pathResourceListaProvince: "/api/v2.0/appecupt/comuni/listaprovince",
      pathResourceStatoCivile: "/api/v2.0/appecupt/statocivile",
      pathResourceListaComuni: "/api/v2.0/appecupt/comuni/listacomuni",
      pathResourceListaNazioni: "/api/v2.0/appecupt/nazioni/",
      token: null,
      nazionalitaStraniera: false,
    };
  },
  computed: {
    scadenzaTesseraSanitaria: {
      get() {
        let me = this;
        let scadenzaTessera = Date(me.jsonData.scadenzaTS);
        return moment(scadenzaTessera).format("DD/MM/YYYY");
      },
      set(value) {
        return moment(value).format("DD/MM/YYYY");
      },
    },
    validationCellulare() {
      var regexCellulare = /^3\d{8,9}$/;

      return regexCellulare.test(this.jsonData.numeroCellulare) ? true : false;
    },
    validationFisso() {
      let regexFisso = /^0\d+$/;
      return !this.jsonData.numeroFisso || this.jsonData.numeroFisso.length === 0 ? null : regexFisso.test(this.jsonData.numeroFisso) ? true : false;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
    me.loadListaProvince();
    me.loadStatoCivile();
    me.controlloNazionalita();
  },
  methods: {
    loadListaProvince() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaProvince;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.provinciaResidenzaOptions.push({
              value: element,
              text: element.provincia.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadStatoCivile() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceStatoCivile;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.statoCivileOptions.push({
              value: element,
              text: element.descrizione.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedProvinciaResidenza(provinciaResidenza) {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaComuni;
      me.comuniResidenzaOptions = [];
      axios
        .post(
          link,
          { codIstat: provinciaResidenza.codIstat },
          {
            headers: {
              Authorization: `Bearer ${me.token}`,
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            me.comuniResidenzaOptions.push({
              value: element,
              text: element.nome.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    controlloNazionalita() {
      let me = this;
      if (me.jsonData.codiceNazionalita !== 100) me.nazionalitaStraniera = true;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceListaNazioni;
      axios
        .get(link, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            me.cittadinanzaOptions.push({
              value: element,
              text: element.descrizione,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
