import BaseService from "./BaseService";

export default class Pathologies extends BaseService {

  static async getPathologies(companyId){
    return await this.perform({
      url: '/pathologies',
      method: 'get',
      params: {company_id: companyId}
    })
  }

  static async getPathologiesById(id){
    return await this.perform({
      url: '/pathologies',
      method: 'get',
      params: {id: id}
    })
  }

  static async createPathologies(body){
    return await this.perform({
      url: '/pathologies/',
      method: 'post',
      body: body
    })
  }
  static async deletePathologies(id){
    return await this.perform({
      url: '/pathologies/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updatePathologies(id, body){
    return await this.perform({
      url: '/pathologies/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
