import BaseService from "./BaseService";

export default class Companies extends BaseService {

  static async getCompanies(id) {
    return await this.perform({
      url: '/companies',
      method: 'GET',
      params: {id: id}
    })
  }

  static async createCompanies(body){
    return await this.perform({
      url: '/companies/',
      method: 'POST',
      body: body
    })
  }

  static async deleteCompanies(id) {
    return await this.perform({
      url: '/companies/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateCompanies(id, body) {
    return await this.perform({
      url: '/companies/',
      method: 'patch',
      body: body,
      params: {id: id}
    })
  }
}
