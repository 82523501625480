<template>
  <div>
    <b-button v-b-modal.modal-3 variant="outline-success"><i class="fab fa-youtube"></i> Tutorial</b-button>

    <div class="separator border-4 my-3"></div>

    <b-container class="bv-example-row">
      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Username</strong></div>
          <b-form-input
            v-model.trim="jsonData.username"
            type="text"
            class="form-control form-control-solid"
            name="username"
            placeholder=""
            value=""
            required
            :state="validationUsername"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Password</strong>
          </div>
          <b-form-input
            type="password"
            class="form-control form-control-solid"
            name="password"
            placeholder=""
            value=""
            v-model.trim="jsonData.password"
            required
            :state="validationPassword"
            minlength="8"
            maxlength="32"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px">
            <strong>Conferma Password</strong>
          </div>
          <b-form-input
            type="password"
            class="form-control form-control-solid"
            name="password2"
            placeholder=""
            value=""
            v-model="jsonData.confermaPassword"
            required
            :state="validationConfermaPassword"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>E-mail</strong></div>
          <b-form-input
            type="email"
            class="form-control form-control-solid"
            name="email"
            placeholder=""
            value=""
            v-model.trim="jsonData.email"
            required
            :state="validationEmail"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>

        <b-col sm class="mb-7 mt-5">
          <div class="h-35px"><strong>Conferma E-mail</strong></div>
          <b-form-input
            type="email"
            class="form-control form-control-solid"
            name="email2"
            placeholder=""
            value=""
            v-model="jsonData.confermaEmail"
            :state="validationConfermaEmail"
            @keydown.space.prevent
            @keyup.enter="nextStep"
          />
        </b-col>
      </b-row>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </b-container>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import UtilityMixin from "../../utilities/utilityMixin";
import axios from "axios";
export default {
  components: { Loading },
  props: {},
  data() {
    return {
      pathResourceSecondoStepRegistrazione: "/api/v2.0/appecupt/auth/controlloregistrazionestep1",
      jsonData: {
        username: "",
        password: "",
        confermaPassword: "",
        email: "",
        confermaEmail: "",
      },
      state: 0,
      token: null,
      regexSpazio: /^\S*$/,
      isLoading: false,
    };
  },
  computed: {
    validationUsername() {
      return this.jsonData.username.length === 0 ? null : this.regexSpazio.test(this.jsonData.username) && this.jsonData.username.length >= 3 ? true : false;
    },
    validationPassword() {
      var regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
      return this.jsonData.password.length === 0 ? null : regexPassword.test(this.jsonData.password) && this.regexSpazio.test(this.jsonData.password) ? true : false;
    },
    validationConfermaPassword() {
      return this.jsonData.confermaPassword - length === 0 ? null : this.jsonData.confermaPassword === this.jsonData.password ? true : false;
    },
    validationEmail() {
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return this.jsonData.email.length === 0 ? null : regexEmail.test(this.jsonData.email) ? true : false;
    },
    validationConfermaEmail() {
      return this.jsonData.confermaEmail - length === 0 ? null : this.jsonData.confermaEmail === this.jsonData.email ? true : false;
    },
  },
  mounted: function () {
    let me = this;
    me.token = localStorage.getItem("tokenWSO2");
  },
  methods: {
    nextStep() {
      let me = this;
      let link = process.env.VUE_APP_URL_BACKEND + me.pathResourceSecondoStepRegistrazione;
      UtilityMixin.methods.loginWso2();
      me.token = localStorage.getItem("tokenWSO2");
      if (!me.validationUsername || !me.validationPassword || !me.validationEmail || !me.validationConfermaEmail) return;
      me.isLoading = true;
      axios
        .post(link, me.jsonData, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          if (response.data.stato === 200 || response.data.stato === 201) {
            me.$emit("getInfoAccessoUtente", response.data.data);
            me.isLoading = false;
          } else {
            this.$bvModal
              .msgBoxOk(response.data, {
                title: "Errore",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "vv-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
