<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Nuova patologia</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <b-row>
            <b-col class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Azienda</strong>
              </div>
              <b-form-select class="form-select" :state="validateAs" v-model="jsonData.company_id" :value="null" value-field="id" :options="asOptions" text-field="name" style="width: 25%">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona azienda</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="validateAs">
                Campo obbligatorio.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Nome</strong>
              </div>
              <b-form-input type="text" :state="validateNome" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.name" />
              <b-form-invalid-feedback :state="validateNome">
                Campo obbligatorio.
              </b-form-invalid-feedback>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Info</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="nome" placeholder="Info" value="" v-model.trim="jsonData.info" />
            </b-col>
          </b-row>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSave()"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
import Pathologies from "@/services/Pathologies";
export default {
  components: { Loading },
  data() {
    return {
      jsonData: {
        name: "",
        info: "",
        company_id: null,
      },
      isLoading: false,
      disableComuneResidenza: true,
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      repartoOptions: [],
    };
  },
  computed: {
    validateAs(){
      return this.jsonData.company_id !== null
    },
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    }
  },
  mounted: async function () {
    await this.loadAs()
  },
  methods: {
    async loadAs(){
      const response = await Companies.getCompanies()
      if(response.status === 200){
        for (let i=0; i<response.data.length;i++){
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    async onSave(){
      if(this.validateAs && this.validateNome){
        const result = await Pathologies.createPathologies(this.jsonData)
        if(result.status === 204){
          this.$emit("onIndietro");
        } else {
          console.log(result)
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }
    }
  },
};
</script>
