import BaseService from "./BaseService";

export default class States extends BaseService {

    static async getStateInfo(id){
        return this.perform({
            url: '/states/state_info',
            method: 'get',
            params: {id: id}
        })
    }

    static async getFlow(id){
        return await this.perform({
            url: '/states/state_flow',
            method: 'get',
            params: {id: id}
        })
    }

    static async getAllState(){
        return await this.perform({
            url: '/states/state_list',
            method: 'get'
        })
    }

    static async getAllFlows(){
        return await this.perform({
            url: '/states/state_children',
            method: 'get'
        })
    }

    static async getChildren(id){
        return await this.perform({
            url: '/states/state_children',
            method: 'get',
            params: {parent_id: id}
        })
    }

    static async createState(body){
        return await this.perform({
            url: '/states/',
            method: 'post',
            body: body
        })
    }

    static async updateState(body, id){
        return await this.perform({
            url: '/states/',
            method: 'patch',
            body: body,
            params: {id: id}
        })
    }

    static async deleteState(id){
        return await this.perform({
            url: '/states/',
            method: 'delete',
            params: {id: id}
        })
    }
}
