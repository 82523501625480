<template>
  <div>
    <lista-contatti
      @onEdit="onEdit"
      @onView="onView"
      @onAggiungi="onAggiungi"
      v-if="step === PAGINA_LISTA_CONTATTI"
    ></lista-contatti>
    <modifica-contatti
      @saveEdit="saveEdit"
      v-if="step === PAGINA_MODIFICA_CONTATTO"
      :contatto="jsonData.contattoDaModificare"
    ></modifica-contatti>
    <contatto-view
      v-if="step === PAGINA_CONTATTO_VIEW"
      :contatto="jsonData.contatto"
      @saveEdit="saveEdit"
    ></contatto-view>
    <aggiungi-contatto
      @onIndietro="onIndietro"
      v-if="step === PAGINA_AGGIUNGI_CONTATTO"
    ></aggiungi-contatto>
  </div>
</template>

<script>
import ListaContatti from "./listaContatti.vue";
import ModificaContatti from "./modificaContatti.vue";
import ContattoView from "./contattoView.vue";
import AggiungiContatto from "./aggiungiContatto.vue";

export default {
  components: {
    ListaContatti,
    ModificaContatti,
    ContattoView,
    AggiungiContatto,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_CONTATTI: 0,
      PAGINA_MODIFICA_CONTATTO: 1,
      PAGINA_CONTATTO_VIEW: 2,
      PAGINA_AGGIUNGI_CONTATTO: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_CONTATTO);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_CONTATTI);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_CONTATTO_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_CONTATTO);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_CONTATTI);
    },
  },
};
</script>
