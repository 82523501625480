<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">GESTIONE CHATBOT<br /><span class="titoloM2">Configura i flussi di conversazione</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary ml-5" @click="onIndietro"><i class="fas fa-cog"></i> Pannello</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="openModal"><i class="fa fa-h-square" aria-hidden="true"></i> <strong> Nuovo flusso</strong></b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista flussi</strong>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header mt-5">
              <b-table
                  sticky-header
                  ref="table"
                  id="tbl"
                  responsive
                  stacked="lg"
                  striped
                  hover
                  :items="flussi"
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  sort-icon-left
                  head-variant="light"
                  class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onDetail(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="search" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} stati</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
    <b-modal id="modalAggiungi"
             class="modal-content"
             title="Crea Flusso"
             slot="modal-header"
             hide-header-close
             size="lg"
             ok-title="Salva"
             cancel-title="Annulla"
             @ok="CreateFlusso"
             @cancel="resetData">
      <b-form>
        <label for="nomeStato"> <strong>Nome stato</strong></label>
        <b-form-input :state="validateNome" id="nomeStato" type="text" style="width: 40%" v-model="jsonData.name"/>
        <b-form-invalid-feedback :state="validateNome">
          Campo obbligatorio.
        </b-form-invalid-feedback>        <b-row class="ml-1" align-v="center">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <label class="mt-5 mb-7" for="frasi"><strong>Frasi da riconoscere</strong></label>
          </b-col>
          <b-col xs="1" sm="1" md="1" lg="1" xl="1">
            <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addFrase" data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: left">
              <b-icon icon="plus" variant="outline-secondary" />
            </b-button>
          </b-col>
        </b-row>
          <div id="frasi" v-for="(frasi, index) in jsonData.sentences" :key="index">
            <div class="frasiGrid">
              <b-row>
                <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                  <b-form-input :state="validateFrase" type="textarea" v-model="frasi.text" class="mb-5"></b-form-input>
                  <b-form-invalid-feedback :state="validateFrase">
                    Campo obbligatorio.
                  </b-form-invalid-feedback>
                </b-col>
                <b-col xs="1" sm="1" md="1" lg="1" xl="1">
                  <b-button v-if="jsonData.sentences.length > 1" size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteFrase(index)" data-toggle="tooltip" data-placement="bottom" title="Elimina" style="float: right">
                    <b-icon icon="trash" variant="outline-secondary" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        <label for="risposta"><strong>Risposta</strong></label>
        <b-form-input :state="validateRisposta" id="risposta" type="text" style="width: 40%" v-model="jsonData.answer"/>
        <b-form-invalid-feedback :state="validateRisposta">
          Campo obbligatorio.
        </b-form-invalid-feedback>        <b-row class="ml-1" align-v="center">
          <b-col xs="3" sm="3" md="3" lg="3" xl="3">
            <label class="mt-5 mb-7" for="scelte"><strong>Scelte (opzionale)</strong></label>
          </b-col>
          <b-col xs="1" sm="1" md="1" lg="1" xl="1">
            <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addScelte" data-toggle="tooltip" data-placement="bottom" title="Aggiungi" style="float: left">
              <b-icon icon="plus" variant="outline-secondary" />
            </b-button>
          </b-col>
        </b-row>
        <div id="scelte" v-for="(scelte, index1) in jsonData.options" :key="index1+10000">
          <div class="frasiGrid">
            <b-row>
              <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                <b-form-input type="text" v-model="scelte.value" class="mb-5"></b-form-input>
              </b-col>
              <b-col xs="5" sm="5" md="5" lg="5" xl="5">
                <b-form-select v-model="scelte.next_state" class="mb-5" :options="stateOptions" text-field="name" value-field="name"></b-form-select>
              </b-col>
              <b-col xs="1" sm="1" md="1" lg="1" xl="1">
                <b-button v-if="jsonData.options.length > 1" size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteScelta(index1)" data-toggle="tooltip" data-placement="bottom" title="Elimina" style="float: right">
                  <b-icon icon="trash" variant="outline-secondary" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import States from "@/services/States";
import UtilityMixin from "@/utilities/utilityMixin";

export default {
  components: { Loading },
  data() {
    return{
      flussi: null,
      currentPage: 1,
      perPage: 10,
      rows: 0,
      stateOptions: [],
      jsonData: {
        name: '',
        sentences: [
          {text: ''}
        ],
        answer: '',
        parent_id: null,
        options: [
          {
            value: "",
            next_state: ""
          }
        ]
      },
      fields: [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      isLoading: false
    }
  },
  computed: {
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    },
    validateRisposta(){
      if(this.jsonData.answer === undefined)
        return false
      return this.jsonData.answer.length > 0;
    },
    validateFrase(){
        if(this.jsonData.sentences[0].text === undefined)
          return false
        return this.jsonData.sentences[0].text.length > 0;
    }
  },
  mounted: async function(){
    await this.getFlows()
    await this.totalRows()
  },
  methods: {
    async totalRows() {
      this.rows = this.flussi.length
    },
    onIndietro() {
      this.$router.push("/pannello");
    },
    async openModal() {
      this.resetData()
      const response = await States.getAllState()
      if(response.status === 200){
        console.log(response)
        this.stateOptions = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Stati.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
      this.$bvModal.show('modalAggiungi')
    },
    addFrase(){
      this.jsonData.sentences.push({text: ''})
    },
    deleteFrase(index){
      if(this.jsonData.sentences.length > 1)
        this.jsonData.sentences.splice(index, 1)
    },
    addScelte(){
      this.jsonData.options.push({value: "", next_state: ""})
    },
    deleteScelta(index){
      this.jsonData.options.splice(index, 1)
    },
    async getFlows(){
      const response = await States.getAllFlows()
      if(response.status === 200){
        console.log(response)
        this.flussi = response.data
      } else {
        this.$bvToast.toast("Non sono presenti Flussi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async onDetail(item){
      console.log(item)
      this.$router.push({path: `gestionechatbot/${item.id}`})
    },
    async CreateFlusso(bvModalEvent){
      bvModalEvent.preventDefault()
      if( this.validateNome && this.validateRisposta && this.validateFrase) {
        for(let i=0; i<this.jsonData.options.length; i++){
          if(this.jsonData.options[i].next_state === null || this.jsonData.options[i].value === ""){
            this.jsonData.options.splice(i, 1)
          }
        }
        for(let i=0; i<this.jsonData.sentences.length; i++){
          if(this.jsonData.sentences[i].text === null || this.jsonData.sentences[i].text === ""){
            this.jsonData.sentences.splice(i, 1)
          }
        }
        console.log(this.jsonData)
        const response = await States.createState(this.jsonData)
        if(response.status === 204){
          this.$bvToast.toast("Flusso aggiunto con successo.", {
            title: `Successo`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            noCloseButton: true
          })
          console.log(response)
          this.jsonData = {
            name: '',
            sentences: [
              {text: ''}
            ],
            answer: '',
            parent_id: null,
            options: [
              {
                value: "",
                next_state: ""
              }
            ]
          }
          await this.getFlows()
          await this.totalRows()
        } else {
          this.$bvToast.toast("Non è stato possibile creare il Flusso.", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'warning',
            noCloseButton: true
          })
        }
        this.$nextTick(() => {
          this.$bvModal.hide('modalAggiungi')
        })
      } else {
        this.$bvToast.toast("Errore. Bisogna compilare tutti i campi necessari.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async onDelete(row){
      this.$bvModal
          .msgBoxConfirm(`Sei sicuro di voler eliminare lo stato "${row.name}"?`, {
            title: "Elimina Stato",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const response = await States.deleteState(row.id)
              if(response.status === 204){
                this.$bvToast.toast("Eliminazione avvenuta con successo.", {
                  title: `Successo`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'success',
                  noCloseButton: true
                })
                await this.getFlows()
                await this.totalRows()
              } else {
                this.$bvToast.toast("Non è stato possibile eliminare lo stato. Riprovare più tardi.", {
                  title: `Attenzione`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'warning',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
    resetData(){
      this.jsonData = {
        name: '',
        sentences: [
          {text: ''}
        ],
        answer: '',
        parent_id: null,
        options: [
          {
            value: "",
            next_state: ""
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
