<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br/><span class="titoloM2">Nuovo presidio</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
          <b-row>
              <b-col class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Azienda</strong>
                </div>
                <b-form-select class="form-select" :state="validateAs" v-model="jsonData.company_id" value="id" value-field="id" :options="asOptions" text-field="name"></b-form-select>
                <b-form-invalid-feedback :state="validateAs">
                  Campo obbligatorio.
                </b-form-invalid-feedback>
              </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px">
                <strong class="text-primary">Codice</strong>
              </div>
              <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice" value="" v-model="jsonData.code"/>
            </b-col>
            <b-col sm class="mb-7 mt-5">
              <div class="h-35px"><strong class="text-primary">Nome</strong></div>
              <b-form-input type="text" :state="validateNome" class="form-control form-control-solid" name="nome" placeholder="Nome" value="" v-model.trim="jsonData.name"/>
              <b-form-invalid-feedback :state="validateNome">
                Campo obbligatorio.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <div>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Città</strong></div>
                <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Città" value="" v-model.trim="jsonData.city"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Indirizzo</strong></div>
                <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Indirizzo" value="" v-model.trim="jsonData.address"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px">
                  <strong class="text-primary">Contatti</strong>
                </div>
                <div v-for="(telefono, index) in jsonData.contacts" :key="index">
                  <div  class="contattoGrid">
                    <b-form-input class="form-control form-control-solid" name="cf" placeholder="Contatto" v-model="jsonData.contacts[index]"/>
                    <b-button v-if="jsonData.contacts.length > 1" size="sm" class="no-text mx-1" variant="outline-danger" @click="deleteContatto(index)" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                      <b-icon icon="trash" variant="outline-secondary" />
                    </b-button>
                  </div>
                </div>
                <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="addContatto" data-toggle="tooltip" data-placement="bottom" title="Modifica" style="float: right">
                  <b-icon icon="plus" variant="outline-secondary" />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <span class="text-primary">Orari URP</span>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Lunedì</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[0][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[0][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Martedì</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[1][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[1][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Mercoledì</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[2][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[2][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Giovedì</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[3][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[3][1]"/>
              </b-col>
              </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Venerdì</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[4][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[4][1]"/>

              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Sabato</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[5][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[5][1]"/>
              </b-col>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Domenica</strong></div>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Apertura" value="" v-model.trim="jsonData.urp_opening_times[6][0]" style="margin-bottom: 5px"/>
                  <b-form-input type="time" class="form-control form-control-solid" name="username" placeholder="Chiusura" value="" v-model.trim="jsonData.urp_opening_times[6][1]"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm class="mb-7 mt-5">
                <div class="h-35px"><strong class="text-primary">Modalità ritiro cartella clinica (indirizzo, orario e giorno)</strong></div>
                <b-form-input type="text"  class="form-control form-control-solid" name="username" placeholder="" value="" v-model.trim="jsonData.medical_record_method"/>
              </b-col>
            </b-row>
          </div>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSalva"><i class="fas fa-check"></i> Salva</b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots"
                 :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
import Hospitals from "@/services/Hospitals";

export default {
  components: {Loading},
  data() {
    return {
      pathResourceOspedali: "/hospitals/",
      jsonData: {
        contacts:[""],
        company_id: null,
        urp_opening_times: [
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ],
          [
            "00:00",
            "00:00"
          ]
        ]
      },
      isLoading: false,
      disableComuneResidenza: true,
      asOptions: [{id: null, name: "Seleziona azienda", disabled: true}],
    };
  },
  computed: {
    validateAs(){
      return this.jsonData.company_id !== null
    },
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    },
  },
  mounted: async function () {
    await this.loadAs()
  },

  methods: {
    async loadAs(){
      const response = await Companies.getCompanies()
      if(response.status === 200){
        for (let i=0; i<response.data.length;i++){
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    addContatto(){
      this.jsonData.contacts.push("");
    },
    deleteContatto(index){
      if(this.jsonData.contacts.length > 1)
        this.jsonData.contacts.splice(index, 1)
    },
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
    async onSalva() {
      if (this.validateAs && this.validateNome){
        const result = await Hospitals.createHospitals(this.jsonData)
        if(result.status === 204){
          this.$emit("onIndietro");
        } else {
          console.log(result)
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }
    },
  },
};
</script>
<style>
.contattoGrid{
  display: grid;
  grid-template-columns: 1fr auto;
}

</style>
