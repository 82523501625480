<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione presidi</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onAggiungiDottore"><i class="fa fa-h-square" aria-hidden="true"></i> Nuovo presidio</b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista presidi</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-7 mt-5">
                <b-form-select class="form-select" v-model="jsonData" value="company_id" value-field="id" :options="asOptions" text-field="name" @change="loadData()"></b-form-select>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header mt-5">
              <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="ospedali"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ ospedali.length }} presidi</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import UtilityMixin from "../../utilities/utilityMixin";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
import Hospitals from "@/services/Hospitals";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceOspedali: "/hospitals/",
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: "NOME",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      dottori: [],
      asOptions: [{name: "Seleziona azienda", id: null, disabled: true }],
      isLoading: false,
      jsonData: null,
      ospedali:[]
    };
  },
  computed: {
    rows() {
      return this.dottori.length;
    },
  },
  mounted: async function () {
    await this.loadAs()
  },
  methods: {
    async loadAs(){
      const response = await Companies.getCompanies()
      if(response.status === 200){
        for (let i=0; i<response.data.length;i++){
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    async loadData() {
      this.ospedali =  []
      const response = await Hospitals.getHospitals(this.jsonData)
      if(response.status === 200){
        for (let i=0; i<response.data.length; i++){
          this.ospedali.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Non sono presenti Presidi per questa Azienda.', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    onEdit(item) {
      console.log(item)
      this.$emit("onEdit", item);
    },
    onDelete(item) {
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare il Presidio?", {
            title: "Elimina Presidio",
            footerClass: "p-2",
            centered: true,
          })
      .then(async (response) => {
        if (response) {
          const res = await Hospitals.deleteHospitals(item.id)
          if(res.status === 204){
            this.$bvModal
                .msgBoxOk("Premere ok per procedere",{
                  title: "Presidio Eliminato",
                  size: "md",
                  okVariant: "outline-success",
                  headerClass: "vv-msg-header-success",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {
                  this.isLoading = false;
                  this.loadData();
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                });
          } else {
            this.$bvToast.toast('Riprovare più tardi', {
              title: `Errore`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              noCloseButton: true
            })
          }
        }
      })
        .catch((error) => {
          UtilityMixin.methods.errorAuthWso2(this, error.response);
          this.loadData();
        });
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onSubmit() {
      let me = this;
      me.contatti = me.contatti.filter((element) => element.cognome.toUpperCase().includes(me.filtro.cognome.toUpperCase()));
    },
    onReset() {
      let me = this;
      me.contatti = [];
      me.loadData();
    },
    onAggiungiDottore() {
     this.$emit("onAggiungi");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
  },
};
</script>
