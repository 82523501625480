<template>
  <div>
    <lista-as @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_AS"></lista-as>
    <modifica-as @saveEdit="saveEdit" @onIndietro="onIndietro" v-if="step === PAGINA_MODIFICA_AS" :as="jsonData.as"></modifica-as>
    <as-view v-if="step === PAGINA_AS_VIEW" :contatto="jsonData.contatto" @saveEdit="saveEdit"></as-view>
    <aggiungi-as @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_AS"></aggiungi-as>
  </div>
</template>

<script>
import ListaAs from "./listaAs.vue";
import ModificaAs from "./modificaAs.vue";
import AsView from "./asView.vue";
import AggiungiAs from "./aggiungiAs.vue";

export default {
  components: {
    ListaAs,
    ModificaAs,
    AsView,
    AggiungiAs,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_AS: 0,
      PAGINA_MODIFICA_AS: 1,
      PAGINA_AS_VIEW: 2,
      PAGINA_AGGIUNGI_AS: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.as = item;
      me.setStep(me.PAGINA_MODIFICA_AS);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_AS);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_AS_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_AS);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_AS);
    },
  },
};
</script>
