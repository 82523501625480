import BaseService from "./BaseService";

export default class QAs extends BaseService {

  static async getQas(id) {
    return await this.perform({
      url: '/qas',
      method: 'get',
      params: {id: id}
    })
  }

  static async createQas(body) {
    return await this.perform({
      url: '/qas/',
      method: 'post',
      body: body
    })
  }

  static async deleteQas(id) {
    return await this.perform({
      url: '/qas/',
      method: 'delete',
      params: {id: id}
    })
  }

  static async updateQas(id, body) {
    return await this.perform({
      url: '/qas/',
      method: 'patch',
      params: {id: id},
      body: body
    })
  }
}
