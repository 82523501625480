<template>
  <div class="mt-10 container">
    <div class="container lh">
      <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Nuovo dipartimento</span></p>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onIndietro"><i class="fas fa-list"></i> Lista</b-button>
        </b-col>
      </b-row>
      <b-container class="bv-example-row">
        <div class="mt-5 container card_container">
            <b-row>
              <b-col class="mb-7 mt-5" xl="4" lg="4" md="4" sm="6">
                <div class="h-35px">
                  <strong class="text-primary">Azienda</strong>
                </div>
                <b-form-select class="form-select" v-model="jsonData.id" :state="validateAs" value-field="id" :options="asOptions" text-field="name" @change="loadHospitals()">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Seleziona azienda</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="validateAs">
                  Campo obbligatorio.
                </b-form-invalid-feedback>
              </b-col>
              <b-col class="mb-7 mt-5" xl="4" lg="4" md="4" sm="6">
                <div class="h-35px">
                  <strong class="text-primary">Presidio</strong>
                </div>
                <b-form-select class="form-select" v-model="jsonData.hospital_id" :state="validateHospital" value-field="id" :options="ospedaleOptions" text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Seleziona presidio</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="validateHospital">
                  Campo obbligatorio.
                </b-form-invalid-feedback>
              </b-col>
              <b-col class="mb-7 mt-5" xl="4" lg="4" md="4" sm="6">
                <div class="h-35px">
                  <strong class="text-primary">Codice</strong>
                </div>
                <b-form-input type="text" class="form-control form-control-solid" name="cf" placeholder="Codice" v-model="jsonData.code" />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-7 mt-5" xl="4" lg="4" md="4" sm="6">
                <div class="h-35px">
                  <strong class="text-primary">Nome</strong>
                </div>
                <b-form-input type="text" class="form-control form-control-solid" :state="validateNome" name="nome" placeholder="Nome" v-model.trim="jsonData.name" />
                <b-form-invalid-feedback :state="validateNome">
                  Campo obbligatorio.
                </b-form-invalid-feedback>
              </b-col>
              <b-col class="mb-7 mt-5" xl="4" lg="4" md="4" sm="6">
                <div class="h-35px"><strong class="text-primary">Capo dipartimento</strong></div>
                <b-form-input type="text" class="form-control form-control-solid" name="username" placeholder="Capo dipartimento" v-model.trim="jsonData.chief" />
              </b-col>
            </b-row>
          <div class="footer d-flex justify-content-end">
            <b-button class="ml-2 btn-primary" @click="onSave()"> <i class="fas fa-check"></i> Salva </b-button>
          </div>
        </div>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
      </b-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Hospitals from "@/services/Hospitals";
import Companies from "@/services/Companies";
import Departments from "@/services/Departments";
export default {
  components: { Loading },
  data() {
    return {
      pathResourceDipartimenti: "/departments/",
      jsonData: {
        id: null,
        hospital_id: null
      },
      isLoading: false,
      disableComuneResidenza: true,
      asOptions: [],
      ospedaleOptions: [],
    };
  },
  computed: {
    validateAs(){
      return this.jsonData.id !== null
    },
    validateHospital(){
      return this.jsonData.hospital_id !== null
    },
    validateNome(){
      if(this.jsonData.name === undefined)
        return false
      return this.jsonData.name.length > 0;
    }
  },
  mounted: async function () {
    await this.loadAs()
  },

  methods: {
    async loadAs(){
      const response = await Companies.getCompanies()
      if(response.status === 200){
        for (let i=0; i<response.data.length;i++){
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    async loadHospitals(){
      this.ospedaleOptions =  []
      this.jsonData.hospital_id = null
      const response = await Hospitals.getHospitals(this.jsonData.id)
      if(response.status === 200){
        for (let i=0; i<response.data.length; i++){
          this.ospedaleOptions.push(response.data[i])
        }
        this.jsonData.hospital_id = this.ospedaleOptions[0].id
        console.log(this.jsonData.hospital_id)
      } else {
        this.$bvToast.toast('Non sono presenti ospedali per questa As.', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async onSave(){
      if(this.validateHospital && this.validateAs && this.validateNome) {
        delete this.jsonData.id
        const response = await Departments.createDepartments(this.jsonData)
        if (response.status === 204){
          this.$emit("onIndietro");
        } else {
          console.log(response)
          this.$bvToast.toast('Si è verificato un errore, riprovare più tardi.', {
            title: `Errore`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            noCloseButton: true
          })
        }
      }
    },
    onIndietro() {
      let me = this;
      me.$emit("onIndietro");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
  },
};
</script>
