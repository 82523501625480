<template>
  <div>
    <lista-servizi @onEdit="onEdit" @onView="onView" @onAggiungi="onAggiungi" v-if="step === PAGINA_LISTA_SERVIZI"></lista-servizi>
    <modifica-servizio @onIndietro="onIndietro" @saveEdit="saveEdit" v-if="step === PAGINA_MODIFICA_SERVIZIO" :contatto="jsonData.contattoDaModificare"></modifica-servizio>
    <aggiungi-servizio @onIndietro="onIndietro" v-if="step === PAGINA_AGGIUNGI_SERVIZIO"></aggiungi-servizio>
  </div>
</template>

<script>
import ListaServizi from "./listaServizi.vue";
import ModificaServizio from "./modificaServizio.vue";
import AggiungiServizio from "./aggiungiServizio.vue";

export default {
  components: {
    AggiungiServizio,
    ListaServizi,
    ModificaServizio,
  },
  data() {
    return {
      jsonData: {
        contattoDaModificare: null,
      },
      PAGINA_LISTA_SERVIZI: 0,
      PAGINA_MODIFICA_SERVIZIO: 1,
      // PAGINA_AS_VIEW: 2,
      PAGINA_AGGIUNGI_SERVIZIO: 3,
      step: 0,
    };
  },
  methods: {
    setStep(step) {
      let me = this;
      me.step = step;
    },
    getStep() {
      let me = this;
      return me.step;
    },
    onEdit(item) {
      let me = this;
      me.jsonData.contattoDaModificare = item;
      me.setStep(me.PAGINA_MODIFICA_SERVIZIO);
    },
    saveEdit() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_SERVIZI);
    },
    onView(item) {
      let me = this;
      me.jsonData.contatto = item;
      me.setStep(me.PAGINA_AS_VIEW);
    },
    onAggiungi() {
      let me = this;
      me.setStep(me.PAGINA_AGGIUNGI_SERVIZIO);
    },
    onIndietro() {
      let me = this;
      me.setStep(me.PAGINA_LISTA_SERVIZI);
    },
  },
};
</script>
