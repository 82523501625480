<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione base di conoscenza</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col sm
          ><div class="card_pic mb-5" @click="onAS()">
            <p class="text-center"><img alt="nuovo utente" src="../../assets/inserisci_as.svg" class="h-150px" /></p>
            <p class="text-center">Gestione aziende</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5">
            <p class="text-center" @click="onOspedale()"><img alt="nuovo utente" src="../../assets/inserisci_ospedale.svg" class="h-150px" /></p>
            <p class="text-center">Gestione presidi</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5">
            <p class="text-center" @click="onDipartimento()"><img alt="nuovo utente" src="../../assets/inserisci_dipartimento.svg" class="h-150px" /></p>
            <p class="text-center">Gestione dipartimenti</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5" @click="onReparto()">
            <p class="text-center"><img alt="nuovo utente" src="../../assets/inserisci_reparto.svg" class="h-150px" /></p>
            <p class="text-center">Gestione unità operative</p>
          </div></b-col
        >
      </b-row>
      <b-row>
        <b-col sm
          ><div class="card_pic mb-5" @click="onBranca()">
            <p class="text-center"><img alt="nuovo utente" src="../../assets/inserisci_branca.svg" class="h-150px" /></p>
            <p class="text-center">Gestione branche</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5">
            <p class="text-center" @click="onDottore()"><img alt="nuovo utente" src="../../assets/inserisci_dottore.svg" class="h-150px" /></p>
            <p class="text-center">Gestione op. sanitari</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5">
            <p class="text-center" @click="onServizi()"><img alt="nuovo utente" src="../../assets/inserisci_servizi.svg" class="h-150px" /></p>
            <p class="text-center">Gestione servizi</p>
          </div></b-col
        >
        <b-col sm
          ><div class="card_pic mb-5" @click="onPatologia()">
            <p class="text-center"><img alt="nuovo utente" src="../../assets/patologie_cure.svg" class="h-150px" /></p>
            <p class="text-center">Gestione patologie</p>
          </div></b-col
        >
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      jsonData: {
        assistito: {
          provinciaResidenza: null,
        },
      },
    };
  },
  computed: {},
  mounted: function () {},
  methods: {
    onAS() {
      let me = this;
      me.$router.push("/aziendesanitarie");
    },
    onOspedale() {
      let me = this;
      me.$router.push("/ospedali");
    },
    onDipartimento() {
      let me = this;
      me.$router.push("/dipartimenti");
    },
    onReparto() {
      let me = this;
      me.$router.push("/reparti");
    },
    onBranca() {
      let me = this;
      me.$router.push("/branca");
    },
    onDottore() {
      let me = this;
      me.$router.push("/dottori");
    },
    onServizi() {
      let me = this;
      me.$router.push("/servizi");
    },
    onPatologia() {
      let me = this;
      me.$router.push("/patologia");
    },
  },
};
</script>

<style scoped></style>
