<template>
  <div class="mt-15 container card_container">
    <div class="d-stepper">
      <div class="d-stepper-header d-flex justify-content-around">
        <div class="step-number-content text-center" :class="{ active: step == i }" v-for="(stepItem, i) in steps" :key="i">
          <div class="step-number align-items-center justify-content-center mx-auto" :class="stepNumberClasses(i)">
            <i v-if="step > i" class="fas fa-check"></i>
            <i v-else-if="step === i && fatalError" class="fas fa-exclamation"></i>
            <span v-else>{{ i + 1 }}</span>
          </div>
          <div class="mt-1 small">{{ stepItem.name }}</div>
        </div>
      </div>

      <b-card class="my-4 overflow-hidden" no-body :class="{ 'border-danger': error, 'shake-error': shake }">
        <b-card-body class="container">
          <div v-if="steps[step].icon" class="d-none d-sm-block">
            <i class="fas fa-fw fa-3x mr-4"></i>
          </div>
          <div>
            <h4>{{ step + 1 }}. {{ steps[step].name }}</h4>
            <p class="text-muted">{{ steps[step].desc }}</p>

            <div v-if="!fatalError">
              <transition :name="effect" mode="out-in">
                <keep-alive>
                  <component
                    :store="store"
                    :state="state"
                    :step="step"
                    :setState="setState"
                    ref="step"
                    :is="stepComponent"
                    @loading="loadingAction"
                    @error="errorHandler"
                    @fatal-error="blockStepper"
                    @can-continue="nextStepAction"
                    @set-step="setStep"
                    @getInfoRegistrazione="getInfoRegistrazione"
                    @getInfoAccessoUtente="getInfoAccessoUtente"
                    @getInfoProvincia="getInfoProvincia"
                    :jsonData="jsonData"
                  />
                </keep-alive>
              </transition>
            </div>
            <div v-else>{{ fatalErrorMsg }}</div>
          </div>
        </b-card-body>
      </b-card>

      <div class="footer d-flex justify-content-end" v-if="!fatalError">
        <b-button v-if="step > 0" variant="light" :disabled="loading" class="text-primary" @click="backStep"
          >Indietro
          <!-- <i class="fas fa-angle-double-left"></i> Indietro -->
        </b-button>

        <b-button v-if="step < steps.length - 1" variant="success" class="ml-2" @click="nextStep" :disabled="loading"
          >Avanti
          <!-- <i class="fas fa-angle-double-right"></i> Avanti -->
        </b-button>

        <b-button v-if="steps[step].confirm" variant="success" class="ml-2" @click="confermaRegistrazione">
          {{ steps[step].confirm }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
//import loading from "./loading";
import axios from "axios";
import UtilityMixin from "../../utilities/utilityMixin";
export default {
  mixins: [UtilityMixin],
  name: "DStepper",
  props: {
    steps: { type: Array, default: () => [] },
    initialState: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      store: {
        state: this.initialState,
        setState: this.setState,
        resetState: this.resetState,
      },
      pathResourceRegistrati: "https://auth.ak12srl.it/ecupt/1.0.0/api/v2.0/appecupt/auth/registrazione",
      jsonData: {},
      step: 0,
      loading: false,
      error: false,
      fatalError: false,
      fatalErrorMsg: "",
      effect: "in-out-translate-fade",
      shake: false,
      state: null,
      token: null,
    };
  },
  computed: {
    activeStep() {
      return this.steps[this.step];
    },
    stepComponent() {
      return this.steps[this.step].component;
    },
    // iconClasses() {
    //   if (!this.activeStep.icon) return "";
    //   else if (/\s/.test(this.activeStep.icon)) return this.activeStep.icon;
    //   return `fas ${this.activeStep.icon}`;
    // },
  },
  mounted: function () {
    let me = this;
    UtilityMixin.methods.loginWso2();
    me.token = localStorage.getItem("tokenWSO2");
  },
  methods: {
    setStep(step) {
      if (step >= 1 && step <= this.steps.length) this.step = step - 1;
    },
    resetState() {
      this.store.state = {
        ...this.initialState,
      };
    },
    setState(key, value) {
      this.store.state = {
        ...this.store.state,
        [key]: value,
      };
    },
    errorHandler(payload) {
      this.error = payload;
      this.shake = payload;
      setTimeout(() => {
        this.shake = !payload;
      }, 750);
    },
    blockStepper(msg) {
      this.resetParams();
      this.fatalErrorMsg = msg;
      this.fatalError = true;
    },
    resetParams() {
      this.error = false;
      this.loading = false;
      this.fatalErrorMsg = "";
      this.fatalError = false;
    },
    stepNumberClasses(i) {
      return {
        "bg-primary text-white": this.step === i && !this.fatalError,
        "bg-success text-white": this.step > i && !this.fatalerror,
        "bg-danger text-white": this.fatalError && this.step === i,
        "text-primary": this.step < i,
      };
    },
    nextStep() {
      if (!this.$refs.step.nextStep) return this.nextStepAction();

      if (this.$refs.step.nextStep()) {
        if (!this.loading) {
          this.nextStepAction();
        }
      }
    },
    nextStepAction() {
      this.effect = "in-out-translate-fade";
      this.resetParams();
      if (this.step < this.steps.length - 1) this.step++;
    },
    backStep() {
      this.effect = "out-in-translate-fade";
      this.resetParams();
      if (this.step > 0) this.step--;
    },
    loadingAction(status) {
      this.loading = status;
      if (!status) this.nextStepAction();
    },
    getInfoRegistrazione(infoNuovoUtente) {
      let me = this;
      me.jsonData = {
        ...infoNuovoUtente,
        controlloStep2: infoNuovoUtente.ControlloStep2,
      };
      me.setStep(2);
    },
    getInfoAccessoUtente(infoAccessoUtente) {
      let me = this;
      me.jsonData.infoAccessoUtente = infoAccessoUtente;
      me.jsonData.controlloStep1 = infoAccessoUtente.controlloStep1;
      me.setStep(3);
    },
    getInfoProvincia(infoProvincia) {
      let me = this;
      me.jsonData.provincia = infoProvincia;
    },
    confermaRegistrazione() {
      let me = this;
      const link = me.pathResourceRegistrati;
      UtilityMixin.methods.loginWso2();
      me.token = localStorage.getItem("tokenWSO2");
      const datiRegistrazione = {
        codiceFiscale: me.jsonData.codiceFiscale,
        codiceTS: me.jsonData.codiceTS,
        controlloStep2: me.jsonData.controlloStep2,
        nome: me.jsonData.nome,
        cognome: me.jsonData.cognome,
        dataNascita: me.jsonData.dataNascita,
        sesso: me.jsonData.sesso,
        scadenzaTS: me.jsonData.scadenzaTS,
        luogoDiNascita: me.jsonData.luogoDiNascita,
        istatLuogoDiNascita: me.jsonData.istatLuogoDiNascita,
        username: me.jsonData.infoAccessoUtente.username,
        password: me.jsonData.infoAccessoUtente.password,
        email: me.jsonData.infoAccessoUtente.email,
        controlloStep1: me.jsonData.controlloStep1,
        statoCivile: me.jsonData.statoCivile.descrizione,
        codStatoCivile: me.jsonData.statoCivile.id,
        provinciaResidenza: me.jsonData.provinciaResidenza.provincia,
        codIstatProvinciaResidenza: me.jsonData.provinciaResidenza.codIstat,
        comuneResidenza: me.jsonData.comuneResidenza.nome,
        codIstatComuneResidenza: me.jsonData.comuneResidenza.codice,
        provinciaResidenzaSigla: me.jsonData.comuneResidenza.provinciaSigla,
        strutturaPreferita: "150901",
        istatCittadinanza: me.jsonData.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.cittadinanza.istat : me.jsonData.codiceNazionalita,
        indirizzoResidenza: me.jsonData.indirizzoResidenza,
        capResidenza: me.jsonData.comuneResidenza.cap,
        cittadinanza: me.jsonData.codiceFiscale.substr(11, 1) === "Z" ? me.jsonData.cittadinanza.descrizione : "ITALIA",
        terminiServizio: me.jsonData.terminiServizio,
        numeroCellulare: me.jsonData.numeroCellulare,
        numeroFisso: me.jsonData.numeroFisso,
        codiceNazionalita: me.jsonData.codiceNazionalita,
      };
      axios
        .post(link, datiRegistrazione, {
          headers: {
            Authorization: `Bearer ${me.token}`,
          },
        })
        .then((response) => {
          if (response.data.stato === 201) {
            this.$bvModal
              .msgBoxOk(response.data.data.messaggioSuccessoRegistrazione, {
                title: "Congratulazioni",
                size: "md",
                okVariant: "outline-success",
                headerClass: "vv-msg-header-success",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.$router.replace("/#").catch((error) => {
                  console.log(error);
                });
              })
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          } else {
            this.$bvModal
              .msgBoxOk("Registrazione non avvenuta. Controlla i dati inseriti.", {
                title: "Attenzione",
                size: "md",
                okVariant: "outline-warning",
                headerClass: "vv-msg-header-warning",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {
                me.$router.replace("/#").catch((error) => {
                  console.log(error);
                });
              })
              .catch((err) => {
                me.isLoading = false;
                console.log(err);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
@import "../../assets/custom/style.css";

.d-stepper .d-stepper-header {
  margin: 0 auto;
  position: relative;
}

.d-stepper .d-stepper-header::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ddd;
  top: 20px;
  left: 0;
  content: " ";
}

.d-stepper .step-number {
  background: #e9e9e9;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  display: flex;
}
.d-stepper .step-number-content {
  transition: transform 0.2s;
  z-index: 2;
  width: 108px;
}

.d-stepper .step-number-content div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.d-stepper .step-number-content.active {
  transform: scale(1.25);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}
.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}
.in-out-translate-fade-enter {
  transform: translateX(100px);
}
.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}
.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}
.out-in-translate-fade-enter {
  transform: translateX(-100px);
}
.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
</style>
