<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
    <b-container class="mt-30">
      <b-row> </b-row>
    </b-container>
    <div class="container card_container p-lg-5 mx-auto phone card-login">
      <b-container>
        <b-col sm>
          <img alt="Logo" src="../../assets/logo.png" class="h-100px my-9 ml-12" />
        </b-col>
        <b-row>
          <b-col sm class="mb-5 text-center mt-10">
            <a>
              <b-button size="lg" class="w-100 login-button mb-10" @click="onAccedi"> ACCEDI </b-button>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="modalCookies" scrollable
             ok-title="Accetta"
             cancel-title="Rifiuta"
             @ok="saveCookies"
             hide-header-close
    >
      <table style="height: 10px; width: 100%; border-collapse: collapse; background-color: #c0c0c0; border-color: #ffffff;" border="1">
        <tbody>
        <tr style="height: 10px;">
          <td style="width: 100%; height: 10px;">
            <h3><strong>COOKIE POLICY</strong></h3>
          </td>
        </tr>
        </tbody>
      </table>
      <p>Il Sito WEB utilizza solo cookies tecnici necessari per la gestione dell&rsquo;autenticazione dell&rsquo;utente.</p>
      <p>Navigando il sito, si accetta espressamente la nostra politica di utilizzo dei cookies.</p>
      <p><strong>Cookies necessari:</strong></p>
      <p>Sempre attivi</p>
      <p>I cookie strettamente necessari permettono l&apos;utilizzo di determinate funzioni, senza le quali non &egrave; possibile utilizzare il nostro sito web come previsto. Questi cookie (First Party Cookies) sono esclusivamente utilizzati da noi e non sono riconducibili a te.</p>
      <h4><strong>Cookies tecnici:</strong></h4>
      <p>Sempre attivi</p>
      <p>Questo tipo di cookies permette di gestire l&rsquo;autenticazione dell&rsquo;utente loggato al Sito WEB garantendone la sicurezza delle operazioni.</p>
      <p><strong>Cookies Analitici o Statistici di Prima Parte e di Terze Parti:</strong></p>
      <p>Non sono presenti cookie analitici di prima e di terze parti e in generale nessun dato personale degli utenti viene in proposito acquisito.</p>
      <h4><strong>Cookies di Profilazione o Marketing di Prima Parte e di Terze Parti:</strong></h4>
      <p>Non sono presenti cookie di profilazione di prima parte e di terze parti e in generale nessun dato personale degli utenti viene in proposito acquisito.</p>
      <p>&Egrave; possibile bloccare i Cookies tramite la funzione presente nelle impostazioni del Vostro browser, che permetter&agrave; di rifiutare l&rsquo;impostazione di tutti o di alcuni Cookies. Tuttavia, se vorrete bloccare tutti i Cookies (inclusi anche quelli strettamente necessari), potreste non riuscire poi ad accedere a tutti o ad alcuni contenuti presenti sul Sito.</p>
      <p>Per maggiori dettagli sui cookie si rimanda alla Cookie Policy accessibile a <a :href="cookies" target="_blank"> questo link</a></p>
    </b-modal>
    </b-overlay>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import AuthService from "@/services/AuthService";
import router from "@/router/router";
export default {
  components: { Loading },
  data() {
    return {
      cookies: process.env.VUE_APP_URL_COOKIES,
      data: {},
      loginData: {
        username: null,
        password: null,
      },
      username: null,
      password: null,
      isLoading: false,
    };
  },
  created: function () {},
  mounted: async function () {
    if(localStorage.getItem('cookies') !== 'true'){
      this.$bvModal.show('modalCookies')
    }
    const params = new URLSearchParams(window.location.search)
    params.get('code')
    if(params.get('code')){
      this.isLoading = true
      let info
      for(let i=0; i<localStorage.length; i++){
        if(localStorage.key(i).includes('oidc')){
          info = JSON.parse(localStorage.getItem(localStorage.key(i)))
        }
      }
     const response = await AuthService.getToken(params.get('code'),info.code_verifier)
      if(response.status === 200){
        console.log("Storage",response.data)
        sessionStorage.setItem('access_token', response.data.data.access_token)
        await router.push({
          name: "Pannello",
        })
      } else {
        console.log(response)
        this.$bvToast.toast("Errore nella login", {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
        this.isLoading = false
      }
    }
  },
  methods: {
    async onAccedi() {
      for(let i=0; i<localStorage.length; i++){
        if(localStorage.key(i).includes('oidc')){
          localStorage.removeItem(localStorage.key(i))
        }
      }
      await this.$oidc.login()
      /*router.push({
        name: "Pannello",
      });*/
    },
    saveCookies() {
      localStorage.setItem('cookies', 'true')
    }
  },
};
</script>
<style>
@import "../../assets/custom/style.css";

.modal-content {

}
</style>
