<template>
  <div>
    <div class="mt-10 container">
      <div class="container lh">
        <p class="titoloM text-center">PANNELLO ADMIN<br /><span class="titoloM2">Gestione servizi</span></p>
      </div>
    </div>
    <div class="mt-15 container">
      <b-row>
        <b-col>
          <b-button class="my-2 btn-primary" @click="onPannelloStrutture"><i class="fas fa-cog"></i> Gestione base di conoscenza</b-button>
          <b-button class="my-2 btn-primary ml-1" @click="onAggiungiReparto"><i class="fa fa-plus-square" aria-hidden="true"></i> <strong>Nuovo servizio</strong></b-button>
        </b-col>
      </b-row>
      <div class="mt-5 container card_container">
        <b-container class="bv-example-row">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <strong class="text-primary">Lista servizi</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" :value="null" v-model="as" value-field="id" :options="asOptions" text-field="name" @change="loadHospitals">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona azienda</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" v-model="hospital_id" :value="null" value-field="id" :options="ospedaleOptions" text-field="name" @change="loadDepartments">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona presidio</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" v-model="department_id" :value="null" value-field="id" :options="dipartimentoOptions" text-field="name" @change="loadReparti">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona dipartimento</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="mb-7 mt-5">
              <b-form-select class="form-select" v-model="jsonData.ward_id" :value="null" value-field="id" :options="repartoOptions" text-field="name" @change="loadService">
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleziona unità operativa</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <template>
            <div class="b-table-sticky-header">
              <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="servizi"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
              >
                <template v-slot:cell(actions)="row">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)" data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary" />
                  </b-button>

                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="onDelete(row.item)">
                    <b-icon icon="trash" variant="outline-danger" data-toggle="tooltip" data-placement="bottom" title="Elimina<"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
          <template>
            <b-row>
              <b-col cols="6">
                <strong class="text-primary"> Sono presenti {{ rows }} serivizi</strong>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-container>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#283f83" loader="dots" :height="120" :width="120"></loading>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Companies from "@/services/Companies";
import Hospitals from "@/services/Hospitals";
import Departments from "@/services/Departments";
import Wards from "@/services/Wards";
import Services from "@/services/Services";
import UtilityMixin from "@/utilities/utilityMixin";
export default {
  components: { Loading },
  data() {
    return {
      as: null,
      hospital_id: null,
      department_id: null,
      jsonData: {
        ward_id: null,
      },
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      servizi: [],
      asOptions: [],
      ospedaleOptions: [],
      dipartimentoOptions: [],
      repartoOptions: [],
      isLoading: false,
    };
  },
  computed: {
    rows() {
      return this.servizi.length;
    },
  },
  mounted: async function () {
    await this.loadAs()
  },
  methods: {
    async loadAs(){
      const response = await Companies.getCompanies()
      if(response.status === 200){
        for (let i=0; i<response.data.length;i++){
          this.asOptions.push(response.data[i])
        }
      } else {
        this.$bvToast.toast('Riprovare più tardi', {
          title: `Errore`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          noCloseButton: true
        })
      }
    },
    async loadHospitals() {
      this.ospedaleOptions =  []
      this.dipartimentoOptions =[]
      this.repartoOptions = []
      this.servizi = []
      this.hospital_id = null
      this.department_id=null
      this.jsonData.ward_id = null
      const response = await Hospitals.getHospitals(this.as)
      if(response.status === 200){
        for (let i=0; i<response.data.length; i++){
          this.ospedaleOptions.push(response.data[i])
        }
        this.hospital_id=this.ospedaleOptions[0].id
        await this.loadDepartments()
      } else {
        this.$bvToast.toast('Non sono presenti ospedali per questa As.', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadDepartments(){
      this.dipartimentoOptions =[];
      this.repartoOptions = []
      this.servizi = []
      this.department_id=null
      this.jsonData.ward_id = null
      const response = await Departments.getDepartments(this.hospital_id)
      if(response.status === 200){
        for(let i=0; i<response.data.length; i++){
          this.dipartimentoOptions.push(response.data[i])
        }
        this.department_id = this.dipartimentoOptions[0].id
        await this.loadReparti()
      } else {
        this.$bvToast.toast("Non sono presenti Dipartimenti per quest'ospedale.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadReparti(){
      this.repartoOptions = []
      this.servizi = []
      this.jsonData.ward_id = null
      const response = await Wards.getWards(this.department_id)
      if(response.status === 200){
        for(let i=0; i<response.data.length; i++){
          this.repartoOptions.push(response.data[i])
        }
        this.jsonData.ward_id = this.repartoOptions[0].id
        await this.loadService()
      } else {
        this.$bvToast.toast("Non sono presenti Reparti per questo dipartimento.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async loadService(){
      this.servizi = [];
      const response = await Services.getServices(this.jsonData.ward_id)
      if(response.status === 200){
        for(let i=0; i<response.data.length; i++){
          this.servizi.push(response.data[i])
        }
      } else {
        this.$bvToast.toast("Non sono presenti Servizi per questo Reparto.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'warning',
          noCloseButton: true
        })
      }
    },
    async onDelete(item){
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare il Servizio?", {
            title: "Elimina Servizio",
            footerClass: "p-2",
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const res = await Services.deleteServices(item.id)
              if(res.status === 204){
                this.$bvModal
                    .msgBoxOk("Premere ok per procedere",{
                      title: "Servizio Eliminato",
                      size: "md",
                      okVariant: "outline-success",
                      headerClass: "vv-msg-header-success",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {
                      this.isLoading = false;
                      this.loadReparti();
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                    });
              } else {
                this.$bvToast.toast('Riprovare più tardi', {
                  title: `Errore`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'danger',
                  noCloseButton: true
                })
              }
            }
          })
          .catch((error) => {
            UtilityMixin.methods.errorAuthWso2(this, error.response);
            this.loadReparti();
          });
    },
    onEdit(item) {
      let me = this;
      me.$emit("onEdit", item);
    },
    onView(item) {
      let me = this;
      me.$emit("onView", item);
    },
    onAggiungiReparto() {
      let me = this;
      me.$emit("onAggiungi");
    },
    onPannelloStrutture() {
      let me = this;
      me.$router.push("/strutture");
    },
  },
};
</script>
