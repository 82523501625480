<template>
  <div>
    <navbar></navbar>
    <div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import navbar from "../navbar/navbar.vue";
export default {
  components: { navbar },
};
</script>